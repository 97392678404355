
import '../styling/constants/typography.css'
import '../styling/constants/divs.css'
import '../styling/home.css';

import React from 'react';
import Moment from 'moment';
import { articles } from '../constants.js';
import { useNavigate } from 'react-router-dom';
import { SecondaryButton } from '../components/mui/Buttons.js';

export default function Blog() {

    const navigate = useNavigate();

    const showArticles = () => {

        var compArray = []

        articles.forEach(element => {
            
            compArray.push(
                <div className='priceTile article'  onClick={()=>openArticle(element.id)}>
                    <div className='row width100 jc-between ai-start gap40'>
                        <span className='alignLeft subtitle2'>{element.title}</span>
                        <span className='caption indigo width40 alignRight'>{formatDate(element.date)}</span>
                    </div>
                    <span className='alignLeft maxLines5 body2'>{element.content}</span>
                    <div className={`column jc-centre primaryButton ai-self-end`}>
                        <SecondaryButton className='buttonSmall'>Read More</SecondaryButton>
                    </div>
                </div>
            )

        });

        return compArray
    }

    const formatDate = (time) =>
    {
       let date = Moment(time).format('MMM DD, YYYY')
       return date
    }


    const openArticle = (id) => {
        navigate(`../article/`+id, { replace: true});
    }


    return (
        
        <div className="column ai-centre borderBox gap40 padding80">
            
            <span className='title3 indigo'>VoxMail Updates</span>

            <div className='grid gap32 ai-start'>
                {showArticles()}
                {showArticles()}
            </div>

            <span className='marginTop20'>Stay tuned for more updates!</span>



        
            
                    
        </div>

    );
}