import { useEffect } from 'react';
import '../styling/voxMailTool.css'
import { useSearchParams } from 'react-router-dom';

export default function VoxMailTool({propsData,sending,addMessage}) {

  const [searchParams] = useSearchParams();

  let key = searchParams.get("key")
  let doc = searchParams.get("doc")
  let viewer = searchParams.get("viewer")

var serviceID = propsData.id
let injected_isContactPageScript = true
let injected_theme = propsData.theme;
let injected_position = 'right';
let injected_tooltipColour = "Dark";
let injected_enterPhone = false;
let injected_hasDropdown = false;


let injected_recordingOption = propsData.recordingOption;
let injected_subscriptionType = propsData.subscriptionType;

let injected_tooltipSubTitle = null//"To leave us a message, please click on the button below."
let injected_tooltipTitle = null//"Leave a message"

var injected_theme_Colour1,injected_theme_Colour2,injected_theme_Colour3,injected_theme_Colour4
if(injected_theme === "Custom"){
    injected_theme_Colour1 = propsData.themeColours.primary
    injected_theme_Colour2 = propsData.themeColours.secondary
    injected_theme_Colour3 = propsData.themeColours.tertiary
    injected_theme_Colour4 = propsData.themeColours.quaternary
}

var injected_dropdownOptions = []
// var apiError = false;

  var openVar = injected_isContactPageScript ? true : false;

  var opened = false

  var step = 2;

  var toggleValue = "speech";
  var speechBubbleAlreadyShown = false;

  var currentlyRecording = false;
  var currentlyPlayingRecording = false;

  var recordedAudio = null;
  var recordedAudioURL = "";
  var recordedAudioDuration = 0;
  var activeMimeType = "";
  var textSubmission = "";
  // var apiError = false;

  var recordedChunks = [];
  var lineHeights = [];  // Store line sizes here
  var mediaRecorder;

  var nameInputVal = propsData.senderName;
  var phoneInputVal =propsData.senderPhone;
  var emailInputVal = propsData.senderEmail;
  var dropdownInputVal = "";

  var currentLocation = "";

  var imageInput;
  var attachFiles = [];

  var interval;
  var currentSeconds = 0;
  var sendingError = false;

  var streamG;


  var pageLanguage = document.documentElement.lang || navigator.language || navigator.userLanguage;
  var language = pageLanguage.split('-')[0];  // Get just the language code (e.g., "en")

  console.log("Language", language)

  var supportedLanguages = {
    "en": {
      "promptTitle": "Leave a message",
      "promptSubTitle": "To leave us a message, please click on the button below."
    },
    "fr": {
      "promptTitle": "Laisser un message",
      "promptSubTitle": "Pour nous laisser un message, veuillez cliquer sur le bouton ci-dessous."
    }
    // Add more languages here
  };


  var stringCollection = supportedLanguages[language] || supportedLanguages["en"];  // Fallback to "en"




  document.addEventListener("keydown", function (event) {
    if (event.key === "Enter" || event.key === " ") {
      // Check if the currently focused element has a tab index (is focusable)
      let focusedElement = document.activeElement;
      if (focusedElement && focusedElement.hasAttribute("tabindex")) {
        // Prevent the default behavior of the Space key (e.g., scrolling)
        event.preventDefault();
        // Simulate a click on the focused element
        focusedElement.click();
      }
    }
  });






  // Image variables setup and begin loading
  let logoHeader = document.createElement("div");
  let logoSVG1 = getThemeSVG(32);
  logoHeader.innerHTML = logoSVG1;

  let logoClosed = document.createElement("div");
  logoClosed.className = 'voxMail logoClosed';
  let logoSVG2 = getThemeSVG(80);
  logoClosed.innerHTML = logoSVG2;

  let poweredBy = new Image();
  poweredBy.className = 'voxMail poweredBy';

  loadImage(poweredBy, 'assets%2FVoxMail_poweredBy_black.png?alt=media&token=bf7b0905-73e4-4bc3-9b49-3f28aa84200e', true);

  // let poweredByWhite = new Image();
  // poweredByWhite.className = 'voxMail poweredBy';
  // loadImage(poweredByWhite, 'assets%2FVoxMail_poweredBy_white.png?alt=media&token=74afa954-2be0-41a4-a1e9-e9d96145aa32', true);

  let introImage = new Image();
  introImage.className = "voxMail introImage";
  loadImage(introImage, 'assets%2FintroImg.png?alt=media&token=b844ede0-b054-431e-8998-c8668dda9ca8', true)

  let nextArrowImg = document.createElement("div");
  nextArrowImg.className = 'voxMail nextArrowImg';
  nextArrowImg.innerHTML = `<svg xmlns="http://www.w3.org/2000/svg" width="39" height="38" viewBox="0 0 39 38" fill="none">
  <path d="M19.4 6.47852L17.1852 8.69328L25.95 17.4738H6.83398V20.6153H25.95L17.1852 29.3958L19.4 31.6105L31.966 19.0445L19.4 6.47852Z" fill="white"/>
</svg>`;

  let sendIcon = document.createElement("div");
  sendIcon.className = 'voxMail recordIcon send';
  sendIcon.innerHTML = `<svg xmlns="http://www.w3.org/2000/svg" width="33" height="32" viewBox="0 0 33 32" fill="none">
  <path d="M5.13327 27.2002L28.3999 17.2269C29.4799 16.7602 29.4799 15.2402 28.3999 14.7735L5.13327 4.80019C4.25327 4.41352 3.27993 5.06685 3.27993 6.01352L3.2666 12.1602C3.2666 12.8269 3.75993 13.4002 4.4266 13.4802L23.2666 16.0002L4.4266 18.5069C3.75993 18.6002 3.2666 19.1735 3.2666 19.8402L3.27993 25.9869C3.27993 26.9335 4.25327 27.5869 5.13327 27.2002Z" fill="white"/>
  </svg>`;

  const newDiv = document.createElement("div");
  newDiv.className = `voxMail mainDiv ${injected_isContactPageScript ? "contactPage" : ""}`;


  let scriptTag = document.createElement(`script`);
  scriptTag.setAttribute('src', "https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/17.0.8/js/intlTelInput.min.js");

  let linkTag = document.createElement('link');
  linkTag.setAttribute('rel', 'stylesheet');
  linkTag.setAttribute('href', "https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/17.0.8/css/intlTelInput.css");


  useEffect(() => {
    getCorrectHTML();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  function getCorrectHTML() {
    newDiv.innerHTML = '';

    //Closed Widget
    if (!openVar) {

      let imageContainer = document.createElement(`div`);
      // imageContainer.id = 'voxMailOpenButton'
      imageContainer.tabIndex = "0"
      imageContainer.role = 'button'
      imageContainer.ariaLabel = "This is the VoxMail tool for leaving voice messages"
      imageContainer.className = `voxMail imageContainer ${injected_position === "right" ? "bottomRight" : "bottomLeft"}`;
      imageContainer.appendChild(logoClosed);

      imageContainer.addEventListener('click', function () {
        openVar = true;



        if (!opened) {
          opened = true;
          sendLog("widgetOpened")
        }

        getCorrectHTML();
      });

      newDiv.appendChild(imageContainer);

      if (!speechBubbleAlreadyShown && !injected_isContactPageScript) {

        // let speechBubble = showSpeechBubblePrompt(injected_tooltipColour,injected_tooltipTitle,injected_tooltipSubTitle, 75, 600000000);
        let speechBubble = showSpeechBubblePrompt(injected_tooltipColour, injected_tooltipTitle, injected_tooltipSubTitle, 7500, 60000);

        newDiv.appendChild(speechBubble);
        speechBubbleAlreadyShown = true;
      }

      let localDiv = document.getElementById('voxMailToolContainer')
      console.log("Local DIV",localDiv)
      localDiv.appendChild(newDiv);
    }

    //Open Widget
    else {

      let openWidgetHtml = getOpenHTML();
      newDiv.appendChild(openWidgetHtml);

      if (injected_isContactPageScript) {
        let widgetContainer = document.getElementById("voxMailToolContainer");
        widgetContainer.appendChild(newDiv);

      } else {
        document.body.appendChild(newDiv);
      }
    }

    document.head.appendChild(linkTag);
    document.head.appendChild(scriptTag);
  }
  // #region SHOW WIDGET COMPONENTS

  //When the widget is open
  function getOpenHTML() {

    let openStateDiv = document.createElement("div");
    openStateDiv.className = `voxMail openWidget column ai-end gap8 ${injected_isContactPageScript ? "" : injected_position === "right" ? "bottomRight" : "bottomLeft"}`;

    //Add Widget Tile
    let widgetTile = getWidgetTileHTML();
    openStateDiv.appendChild(widgetTile);

    //Add Close Widget button
    if (!injected_isContactPageScript) {
      let closeWidgetButton = document.createElement("div");
      closeWidgetButton.className = 'voxMail closeWidgetButton row jc-centre ai-centre';
      closeWidgetButton.innerHTML = `<svg class="voxMail vmCloseButton" xmlns="http://www.w3.org/2000/svg" width="80" height="80" viewBox="0 0 80 80" fill="none">
    <path d="M80 40C80 62.0914 62.0914 80 40 80C17.9086 80 0 62.0914 0 40C0 17.9086 17.9086 0 40 0C62.0914 0 80 17.9086 80 40Z" fill="#3E363F"/>
    <rect x="24.7275" y="29.3557" width="6.54545" height="37.536" rx="3.27273" transform="rotate(-45 24.7275 29.3557)" fill="white"/>
    <rect x="51.2695" y="24.7273" width="6.54545" height="37.536" rx="3.27273" transform="rotate(45 51.2695 24.7273)" fill="white"/>
    </svg>`;

      closeWidgetButton.addEventListener('click', function () {
        openVar = false;
        clearValues();
        step = 0;
        getCorrectHTML();
      });

      openStateDiv.appendChild(closeWidgetButton);
    }

    return openStateDiv;
  }

  //The tile of the open widget
  function getWidgetTileHTML() {

    let widgetTile = document.createElement("div")
    widgetTile.className = 'voxMail widgetTile'

    if (step === 0) {
      //Add Intro Page
      let introPage = getWidgetIntroPageHTML();
      widgetTile.appendChild(introPage);

      widgetTile.appendChild(introImage);
    }
    else {
      //Add Other Pages

      //Add Header
      // let header = getHeaderHTML();
      // widgetTile.appendChild(header);

      //Add Content
      let content = getWidgetContentHTML();
      widgetTile.appendChild(content);
    }

    //Add Footer
    // let footer = getFooterHTML();
    // widgetTile.appendChild(footer);

    return widgetTile;
  }

  //The header of the widgetTile
  // function getHeaderHTML() {

  //   let header = document.createElement("div");
  //   header.className = 'voxMail vmHeader row jc-start ai-centre width100 gap8';

  //   let headerIcon = document.createElement("div");
  //   headerIcon.className = 'row ai-centre'
  //   let logoSVG1 = getThemeSVG(32);
  //   headerIcon.innerHTML = logoSVG1;
  //   header.appendChild(headerIcon);


  //   let headerTextColumn = document.createElement("div");
  //   headerTextColumn.className = 'voxMail column jc-centre ai-start';

  //   let headerTitle = document.createElement("span");
  //   headerTitle.className = 'voxMailText primary other2';
  //   headerTitle.innerHTML = "Send a message";
  //   headerTextColumn.appendChild(headerTitle);

  //   let headerSubTitle = document.createElement("span");
  //   headerSubTitle.className = 'voxMailText secondary caption marginMinus3';
  //   headerSubTitle.innerHTML = "via speech or text";
  //   headerTextColumn.appendChild(headerSubTitle);

  //   header.appendChild(headerTextColumn);

  //   return header;
  // }

  //Progress Bar at bottom of widget
  // function getProgressBarHTML() {

  //   let barArray = [];

  //   var greyCircle = document.createElement(`div`);
  //   greyCircle.className = 'voxMail greyCircle';
  //   var greyCircle2 = document.createElement(`div`);
  //   greyCircle2.className = 'voxMail greyCircle';
  //   var greyCircle3 = document.createElement(`div`);
  //   greyCircle3.className = 'voxMail greyCircle';
  //   var redCircle = document.createElement(`div`);
  //   redCircle.className = 'voxMail redCircle';

  //   barArray.push(greyCircle);
  //   barArray.push(greyCircle2);
  //   if (toggleValue === "speech") {
  //     barArray.push(greyCircle3);
  //     barArray.splice(step - 1, 0, redCircle);
  //   } else {
  //     if (step > 2) {
  //       barArray.splice(step - 2, 0, redCircle);
  //     } else {
  //       barArray.splice(step - 1, 0, redCircle);
  //     }
  //   }

  //   var widgetProgress = document.createElement(`div`);
  //   widgetProgress.className = 'voxMail widgetProgress';

  //   barArray.forEach(element => {
  //     widgetProgress.appendChild(element);
  //   });

  //   return widgetProgress;
  // }

  //The footer of the widgetTile
  // function getFooterHTML() {

  //   let footer = document.createElement("div");
  //   footer.className = 'voxMail vmFooter row jc-centre ai-centre width100';

  //   poweredBy.addEventListener('click', function () {
  //     var link = document.createElement('a');
  //     link.href = 'http://vox-mail.com';
  //     link.target = '_blank';
  //     document.body.appendChild(link); // Necessary to append the link to the document body
  //     link.click();
  //     document.body.removeChild(link); // Clean up after creating the link
  //   });
  //   footer.appendChild(poweredBy);

  //   return footer;
  // }

  function getWidgetIntroPageHTML() {

    let introPage = document.createElement("div")
    introPage.className = 'voxMail column ai-centre gap107 height100 width100 padding24 relative borderBox'

    //Text Column
    let textColumn = document.createElement("div")
    textColumn.className = 'voxMail column gap20 ai-start'

    let spanTitle = document.createElement("span")
    spanTitle.className = 'voxMailText primary title3'
    spanTitle.innerHTML = 'Leave us a message'
    spanTitle.tabIndex = "0"
    textColumn.appendChild(spanTitle)

    let spanSubTitle = document.createElement("span")
    spanSubTitle.className = 'voxMailText primary vmbody1'
    spanSubTitle.innerHTML = `Record a voice message <strong>or</strong> send a typed message. <br/>A member of our team will get back to you as soon as possible. <br/>You can leave your message in <strong>any language</strong>. `
    spanSubTitle.tabIndex = "0"
    textColumn.appendChild(spanSubTitle)

    let spanPrompt = document.createElement("span")
    spanPrompt.className = 'voxMailText primary other3'
    spanPrompt.innerHTML = 'Click  the arrow below to proceed.'
    spanPrompt.tabIndex = "0"
    textColumn.appendChild(spanPrompt)

    introPage.appendChild(textColumn)

    //Add Background image
    let continueOuterCircle = document.createElement("div")
    continueOuterCircle.className = 'voxMail continueOuterCircle row jc-centre ai-centre'

    let continueInnerCircle = document.createElement("div")
    continueInnerCircle.id = 'voxMailArrowButton'
    continueInnerCircle.className = 'voxMail continueInnerCircle row jc-centre ai-centre'
    continueInnerCircle.tabIndex = "0"
    continueInnerCircle.role = "button"
    continueInnerCircle.addEventListener('click', function () {
      step = 1;
      getCorrectHTML();
    });

    continueInnerCircle.innerHTML = `<svg xmlns="http://www.w3.org/2000/svg" width="39" height="38" viewBox="0 0 39 38" fill="none">
  <path d="M19.401 6.43433L17.1862 8.64909L25.951 17.4296H6.83496V20.5711H25.951L17.1862 29.3516L19.401 31.5664L31.967 19.0003L19.401 6.43433Z" fill="white"/>
  </svg>`

    continueOuterCircle.appendChild(continueInnerCircle)
    introPage.appendChild(continueOuterCircle)

    return introPage;
  }

  function getWidgetContentHTML() {

    let content = document.createElement('div');
    content.className = `voxMail height100 width100 padding24 column relative ${getContentGap()} borderBox`;

    //Title and Subtitle Text
    let contentTextColumn = document.createElement('div');
    contentTextColumn.className = 'voxMail width100 column ai-start gap8';

    let contentText = getContentText();

    let title = document.createElement('span');
    title.className = 'voxMailText primary subtitle2bold';
    title.innerHTML = contentText.title;
    title.tabIndex = "0"
    contentTextColumn.appendChild(title);

    let subtitle = document.createElement('span');
    subtitle.className = 'voxMailText primary vmbody2';
    subtitle.innerHTML = contentText.subtitle;
    subtitle.tabIndex = "0"
    contentTextColumn.appendChild(subtitle);

    content.appendChild(contentTextColumn);

    if (step === 1) {
      let step1 = getStep1HTML();
      content.appendChild(step1);
    }

    if (step === 2 || step === 3) {
      let recordingStep = getRecordingStepHTML();
      content.appendChild(recordingStep);
    }

    if (step === 4) {
      let step4 = getStep4HTML();
      content.appendChild(step4);
    }

    if (step === 5) {
      let step5 = getStep5HTML();
      content.appendChild(step5);
    }

    //Progress Bar
    // let progressBar = getProgressBarHTML();
    // content.appendChild(progressBar);

    return content;
  }

  // #endregion

  function getStep1HTML() {

    let step1Div = document.createElement("div");
    step1Div.className = `voxMail width100 column ai-centre ${(injected_enterPhone || injected_hasDropdown) ? "gap16" : "gap80"}`;

    let inputColumn = document.createElement("div");
    inputColumn.className = "voxMail width100 column ai-centre gap24";

    //Name Input
    let nameInput = document.createElement('input');
    nameInput.id = 'voxMailNameInput';
    nameInput.className = 'voxMail textInput voxMailText primary input';
    nameInput.setAttribute('type', 'text');
    nameInput.setAttribute('placeholder', 'Name');
    nameInput.setAttribute('autocomplete', 'name');
    nameInput.ariaLabel = "Name input, you can use voice input"
    nameInput.required = true;
    nameInput.tabIndex = '0'

    if (localStorage.getItem('voxMailNameInput') !== null) {
      nameInput.setAttribute('list', "nameOptions");
      let nameOptions = document.createElement('dataList');
      nameOptions.id = "nameOptions";
      nameOptions.innerHTML = `<option value="${localStorage.getItem('voxMailNameInput')}">`;
      inputColumn.appendChild(nameOptions);

      nameInput.addEventListener('keydown', (event) => {
        if (event.key === 'Enter') {
          nameInput.value = localStorage.getItem('voxMailNameInput');
          event.preventDefault();
        }
      });
    }

    inputColumn.appendChild(nameInput);

    //Phone input
    let phoneInput = document.createElement('input');
    phoneInput.id = 'phoneInput';
    phoneInput.className = 'voxMail textInput phone voxMailText primary input';
    phoneInput.setAttribute('type', 'number');
    phoneInput.setAttribute('placeholder', 'Phone number');
    phoneInput.setAttribute('autocomplete', 'phone');
    phoneInput.required = true;

    if (injected_enterPhone) {
      inputColumn.appendChild(phoneInput);

      window.intlTelInput(phoneInput, {
        utilsScript:
          "https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/17.0.8/js/utils.js",
        preferredCountries: ['IE', 'GB', 'CA', 'US'],
        hiddenInput: "full",
      });
    }

    // Email input
    let emailInput = document.createElement('input');
    emailInput.id = 'voxMailEmailInput';
    emailInput.className = 'voxMail textInput voxMailText primary input';
    emailInput.setAttribute('type', 'email');
    emailInput.setAttribute('placeholder', 'Email');
    emailInput.setAttribute('autocomplete', 'email');
    emailInput.tabIndex = '0'
    // emailInput.value = localStorage.getItem('voxMailEmailInput') || ""
    emailInput.required = true;

    if (localStorage.getItem('voxMailEmailInput') !== null) {
      emailInput.setAttribute('list', "emailOptions");
      let emailOptions = document.createElement('dataList');
      emailOptions.id = "emailOptions";
      emailOptions.innerHTML = `<option value="${localStorage.getItem('voxMailEmailInput')}">`;
      inputColumn.appendChild(emailOptions);

      emailInput.addEventListener('keydown', (event) => {
        if (event.key === 'Enter') {
          emailInput.value = localStorage.getItem('voxMailEmailInput');
          event.preventDefault();
        }
      });
    }

    inputColumn.appendChild(emailInput);






    //Dropdown Input
    let dropdownInput = document.createElement('select')
    dropdownInput.id = 'voxMailDropdownInput'
    dropdownInput.className = `voxMail textInput dropdown voxMailText disabled input`;

    if (injected_hasDropdown) {

      let options = injected_dropdownOptions

      let defaultOption = document.createElement('option')
      defaultOption.innerHTML = "Select option"
      defaultOption.setAttribute("disabled", true)
      defaultOption.setAttribute("selected", true)
      defaultOption.setAttribute("value", true)
      defaultOption.className = 'voxMail textInput voxMailText disabled input';


      dropdownInput.appendChild(defaultOption)

      options.forEach(option => {
        let dropdownOption = document.createElement('option')
        dropdownOption.innerHTML = option
        dropdownOption.className = 'voxMail textInput voxMailText primary input';
        dropdownOption.setAttribute("value", option)
        dropdownInput.appendChild(dropdownOption)
      });

      dropdownInput.onchange = function () {
        // console.log("MAGIC",document.getElementById("voxMailDropdownInput").value)
      };

      inputColumn.appendChild(dropdownInput);
    }

    let submitButton = document.createElement('button');
    submitButton.id = 'voxMailSubmitButton';
    submitButton.className = 'voxMail submitBtn voxMailText buttonSmall';
    submitButton.innerText = 'Continue';
    submitButton.disabled = true;

    inputColumn.addEventListener('input', function () {
      if (nameInput.value !== "" && (phoneInput.value !== "" || !injected_enterPhone) && ((dropdownInput.value !== "" && dropdownInput.value !== "Select option") || !injected_hasDropdown) && (emailInput.value !== "" && validateEmail(emailInput.value))) {
        submitButton.disabled = false;
      } else {
        submitButton.disabled = true;
      }
      if (dropdownInput.value !== "" && dropdownInput.value !== "Select option") {
        dropdownInput.className = `voxMail textInput dropdown voxMailText primary input`;
      }
    });

    // Add click event listener to switch state;
    submitButton.addEventListener('click', function () {

      nameInputVal = nameInput.value;
      emailInputVal = emailInput.value;
      dropdownInputVal = dropdownInput.value;

      localStorage.setItem("voxMailNameInput", nameInputVal)
      localStorage.setItem("voxMailEmailInput", emailInputVal)

      if (injected_enterPhone) {
        var iti = window.intlTelInputGlobals.getInstance(phoneInput);
        phoneInputVal = iti.getSelectedCountryData().dialCode + phoneInput.value;
      }

      console.log("VALS", nameInputVal, emailInputVal, phoneInputVal, dropdownInputVal)

      step++;
      getCorrectHTML();
    });

    step1Div.appendChild(inputColumn);

    step1Div.appendChild(submitButton);

    return step1Div;
  }

  function getRecordingStepHTML() {

    let recordingStep = document.createElement("div");
    recordingStep.className = 'voxMail width100 column ai-centre gap24';

    //Add toggle and attachment buttons
    let toggleAttachmentRow = document.createElement("div");
    toggleAttachmentRow.className = 'voxMail width100 row jc-centre relative';

    let toggle = getToggleHTML();
    toggleAttachmentRow.appendChild(toggle);

    let attachButton = getAttachButton();
    toggleAttachmentRow.appendChild(attachButton);

    recordingStep.appendChild(toggleAttachmentRow);

    if (toggleValue === "text") {
      let textInput = getTextInputHTML();
      recordingStep.appendChild(textInput);
    }
    else {
      let speechInput = getSpeechInputHTML();
      recordingStep.appendChild(speechInput);
    }

    return recordingStep;
  }

  function getSpeechInputHTML() {
    let speechInput = document.createElement("div");
    speechInput.className = "voxMail width100 column ai-centre gap4";

    let recorder = getSpeechInputRecorderHTML();
    let buttons = getSpeechInputButtonsHTML();
    speechInput.appendChild(recorder);
    speechInput.appendChild(buttons);

    return speechInput;
  }

  function getSpeechInputRecorderHTML() {
    let recorderSection = document.createElement('div');
    recorderSection.className = 'voxMail width100 column ai-end gap4';

    //Add Times
    let times = getRecorderTimesHTML();
    recorderSection.appendChild(times);

    //Add Recorder
    let rec = getRecordingRectangleHTML();
    recorderSection.appendChild(rec);

    //Add Current Time
    let currentTime = document.createElement('span');
    currentTime.className = 'voxMailText hotOrange caption1bold';
    currentTime.id = 'currentTimeText';
    currentTime.innerHTML = currentSeconds === 0 ? "00:00" : getTimeString(currentSeconds);
    recorderSection.appendChild(currentTime);

    return recorderSection;
  }

  function getRecorderTimesHTML() {
    let timeRow = document.createElement('div')
    timeRow.id = 'voxMail_timeRow';
    timeRow.className = 'voxMail width100 row ai-centre jc-between'

    let recordTime = getRecordingTime();

    let midTime = recordTime / 2;
    let midTimeMin = Math.floor(midTime / 60);
    var midTimeSec = midTime % 60;
    if (midTimeSec < 10) { midTimeSec = "0" + midTimeSec }
    let maxTimeMin = Math.floor(recordTime / 60);
    var maxTimeSec = recordTime % 60;
    if (maxTimeSec < 10) { maxTimeSec = "0" + maxTimeSec }

    let time1 = document.createElement('span');
    time1.innerText = '00:00';
    time1.className = 'voxMailText primary captionMini';
    timeRow.appendChild(time1);

    if (step === 2) {

      let time3 = document.createElement('span');
      time3.className = 'voxMailText primary captionMini';
      time3.innerText = midTimeMin + ':' + midTimeSec;
      timeRow.appendChild(time3);

      let time5 = document.createElement('span');
      time5.className = 'voxMailText primary captionMini';
      time5.innerText = maxTimeMin + ':' + maxTimeSec;
      timeRow.appendChild(time5);
    }

    if (recordedAudio !== null) {
      let time2 = document.createElement('span');
      let duration = recordedAudioDuration;
      let floor = Math.floor(duration);
      var time = getTimeString(floor);
      time2.innerText = time;
      time2.className = 'voxMailText primary captionMini';
      timeRow.appendChild(time2);
    }

    return timeRow;
  }

  function getRecordingRectangleHTML() {
    let recordingRectangle = document.createElement('div');
    recordingRectangle.className = 'voxMail recordingRectangle';

    let recHorizontalLine = document.createElement('div');
    recHorizontalLine.className = "voxMail recHorizontalLine"
    recordingRectangle.appendChild(recHorizontalLine);


    let canvas = document.createElement('canvas');
    canvas.id = 'voxMail_canvas';
    canvas.className = 'voxMail canvas';
    canvas.width = 320;
    canvas.height = 64;
    recordingRectangle.appendChild(canvas);

    let redLineRecord = document.createElement('div');
    redLineRecord.className = 'voxMail redLineRecord moving';
    redLineRecord.id = 'voxMail_redLineRecord';
    recordingRectangle.appendChild(redLineRecord);

    let redBoxRecord = document.createElement('div');
    redBoxRecord.className = 'voxMail redBoxRecord moving';
    redBoxRecord.id = 'voxMail_redBoxRecord';
    recordingRectangle.appendChild(redBoxRecord);

    if (step === 3) {
      drawRecordedAudioOnCanvas(canvas);
    }

    return recordingRectangle;
  }

  function getSpeechInputButtonsHTML() {
    let recordingBtnsRow = document.createElement('div');
    recordingBtnsRow.className = 'voxMail width100 row jc-centre ai-centre gap24'
    recordingBtnsRow.id = 'voxMail.recordingBtnsRow'

    if (step === 2) {
      let btn = getRecordingButton(currentlyRecording ? "stop" : "record");
      recordingBtnsRow.appendChild(btn)
    }
    else {
      let btn1 = getRecordingButton(currentlyPlayingRecording ? "pause" : "play");
      let btn2 = getRecordingButton('send');
      let btn3 = getRecordingButton('redo');

      recordingBtnsRow.appendChild(btn3);
      recordingBtnsRow.appendChild(btn1);
      recordingBtnsRow.appendChild(btn2);
    }

    return recordingBtnsRow
  }

  function getTextInputHTML() {
    let textInputColumn = document.createElement('div')
    textInputColumn.className = 'voxMail width100 column ai-centre gap11'

    //Add Text Area

    let textInput = document.createElement('textArea');
    textInput.className = 'voxMail textArea voxMailText primary vmbody2';
    textInput.setAttribute('type', 'text');
    textInput.setAttribute('placeholder', 'Type your message');
    textInput.required = true;
    textInput.innerHTML = textSubmission
    textInputColumn.appendChild(textInput);


    //Add Submit Button
    let submitButton = document.createElement('button');
    submitButton.id = 'voxMailSubmitButton';
    submitButton.className = 'voxMail submitBtn voxMailText buttonSmall';
    submitButton.innerText = 'Submit';
    submitButton.disabled = textSubmission === "";

    // Add event listener to enable submit button once the inputs are valid;
    textInputColumn.addEventListener('input', function () {
      if (textInput.value !== "") {
        textSubmission = textInput.value
        submitButton.disabled = false;
      } else {
        submitButton.disabled = true;
      }
    });

    // Add click event listener to switch state;
    submitButton.addEventListener('click', function () {
      textSubmission = textInput.value

      //Send Audio
      sendAudio();



      setTimeout(function () {
        step = 5;
        getCorrectHTML();
      }, 5000);
    });

    textInputColumn.appendChild(submitButton);

    return textInputColumn
  }



  function getStep4HTML() {
    let step4 = document.createElement("div")
    step4.className = "voxMail width100 row jc-centre relative"

    let spinningCircle = document.createElement("div")
    spinningCircle.className = "voxMail sendingCircle"

    spinningCircle.innerHTML = `<svg xmlns="http://www.w3.org/2000/svg" width="121" height="121" viewBox="0 0 121 121" fill="none">
  <ellipse cx="59.8557" cy="60.7214" rx="58.4134" ry="58.4133" stroke="black" stroke-width="0.718655"/>
  <path d="M77.6622 3.80255C76.9029 3.57125 76.0998 3.99931 75.8685 4.75867C75.6372 5.51803 76.0652 6.32112 76.8246 6.55243L77.6622 3.80255ZM60.198 118.041C32.5003 118.041 9.41587 98.2754 4.28551 72.0795L1.46449 72.632C6.85397 100.151 31.0995 120.915 60.198 120.915V118.041ZM117.174 61.0647C117.174 92.5316 91.665 118.041 60.198 118.041V120.915C93.2526 120.915 120.049 94.1192 120.049 61.0647H117.174ZM76.8246 6.55243C100.182 13.6674 117.174 35.3836 117.174 61.0647H120.049C120.049 34.0835 102.196 11.2758 77.6622 3.80255L76.8246 6.55243Z" fill="#D73907"/>
</svg>`


    let imgDiv = document.createElement("div")
    imgDiv.className = "voxMail sendingImage"
    imgDiv.innerHTML = `<svg xmlns="http://www.w3.org/2000/svg" width="90" height="106" viewBox="0 0 90 106" fill="none">
  <path d="M51.7751 72.3653L38.7789 54.2402C38.5851 53.9669 38.2596 53.8095 37.9224 53.8376L18.9818 54.984C18.6111 55.0007 18.2884 55.238 18.1335 55.5729L10.2872 73.5923C10.2872 73.5923 10.2433 73.6952 10.2274 73.7501C10.2184 73.793 10.2 73.8384 10.2005 73.8788C10.1871 73.9432 10.1857 74.0145 10.1843 74.0859C10.1853 74.1667 10.2028 74.2331 10.2203 74.2994C10.2353 74.3563 10.2504 74.4132 10.2843 74.465C10.2968 74.5124 10.3283 74.5548 10.3573 74.5877C10.3598 74.5972 10.3648 74.6162 10.3673 74.6256L23.3635 92.7508C23.5208 92.9627 23.7484 93.0953 24.0059 93.149C24.1322 93.1664 24.2724 93.1598 24.3981 93.1367C24.5238 93.1137 24.6371 93.0432 24.7338 92.9873L51.5696 73.7376C51.772 73.5828 51.9166 73.3622 51.9583 73.0977C52.0025 72.8427 51.9444 72.5842 51.7871 72.3723L51.7751 72.3653ZM24.3819 90.8091L13.0324 74.9665L12.2818 73.9277L12.6788 73.0118L19.6987 56.8935L37.1425 55.8381L37.5132 55.8213L38.6345 57.3808L49.6134 72.7027L24.3914 90.8066L24.3819 90.8091Z" fill="#231F20"/>
  <path d="M37.1344 55.8495L30.2046 72.5016L12.6826 73.0302L19.7001 56.9024L37.1344 55.8495Z" fill="#B6AAF6"/>
  <path d="M49.6156 72.7116L24.3936 90.8155L13.0441 74.9729L30.9084 74.4351C30.9772 74.4271 31.0461 74.419 31.1125 74.4015C31.4063 74.324 31.6556 74.1162 31.7826 73.8292L38.6412 57.3682L49.62 72.6901L49.6156 72.7116Z" fill="#F1EDFF"/>
  <path d="M39.1633 56.0846L38.6264 57.3923L31.7679 73.8533C31.6503 74.1378 31.3915 74.348 31.0977 74.4256C31.0313 74.4431 30.9625 74.4511 30.8936 74.4592L13.0293 74.997L11.1897 75.0567C10.838 75.0684 10.5265 74.887 10.3421 74.6112C10.3132 74.5783 10.2912 74.5334 10.2692 74.4885C10.2542 74.4317 10.2202 74.3798 10.2052 74.3229C10.1877 74.2566 10.1702 74.1902 10.1691 74.1094C10.1706 74.038 10.172 73.9667 10.1855 73.9023C10.1849 73.8619 10.2034 73.8165 10.2123 73.7735C10.2282 73.7186 10.2536 73.6613 10.272 73.6158C10.437 73.3188 10.7577 73.1125 11.1094 73.1008L12.6567 73.0473L30.1812 72.5282L37.111 55.876L37.3301 55.362C37.5448 54.8693 38.1219 54.6359 38.6121 54.8411C39.1022 55.0463 39.3451 55.621 39.1399 56.1111L39.1633 56.0846Z" fill="#231F20"/>
  <path d="M30.9312 62.0087C30.5426 62.1112 30.1084 62.0028 29.8048 61.6976L20.2215 51.817C19.7756 51.3568 19.7876 50.6337 20.2478 50.1878C20.6985 49.7444 21.431 49.7539 21.8769 50.2141L31.4601 60.0947C31.906 60.555 31.8941 61.278 31.4339 61.7239C31.2883 61.8637 31.1113 61.9611 30.9312 62.0087Z" fill="#D73907"/>
  <path d="M44.6953 56.3489C44.3067 56.4514 43.8725 56.343 43.5689 56.0378L18.5047 30.2188C18.0588 29.7586 18.0708 29.0356 18.531 28.5897C18.9817 28.1463 19.7142 28.1557 20.1601 28.6159L45.2243 54.4349C45.6702 54.8952 45.6583 55.6182 45.1981 56.0641C45.0525 56.2039 44.8754 56.3013 44.6953 56.3489Z" fill="#D73907"/>
  <path d="M47.8229 45.1107C47.4343 45.2133 47.0001 45.1049 46.6965 44.7997L38.3836 36.2364C37.9377 35.7762 37.9497 35.0532 38.4099 34.6073C38.8606 34.1639 39.5931 34.1733 40.039 34.6335L48.3519 43.1968C48.7978 43.6571 48.7859 44.3801 48.3257 44.826C48.1801 44.9658 48.003 45.0632 47.8229 45.1107Z" fill="#D73907"/>
  <path d="M26.3904 67.892C26.0018 67.9946 25.5676 67.8861 25.264 67.581L20.0145 62.1734C19.5686 61.7132 19.5805 60.9902 20.0407 60.5443C20.4915 60.1009 21.2239 60.1103 21.6698 60.5705L26.9194 65.9781C27.3653 66.4383 27.3533 67.1613 26.8931 67.6072C26.7475 67.747 26.5705 67.8445 26.3904 67.892Z" fill="#D73907"/>
  </svg>`


    step4.appendChild(spinningCircle)
    step4.appendChild(imgDiv)

    return step4
  }

  function getStep5HTML() {
    let step5 = document.createElement("div")
    step5.className = "voxMail width100 column ai-centre gap160"


    let step5text = document.createElement("span")
    step5text.className = 'voxMailText primary vmbody2'
    step5text.innerHTML = sendingError ? 'You can retry now or try again later.' : `You can expect to hear back from one of our team members via the email address you provided. 
  Have a great day!`

    let submitButton = document.createElement('button');
    submitButton.id = 'voxMailSubmitButton';
    submitButton.className = 'voxMail submitBtn newMessage voxMailText buttonSmall';
    submitButton.innerText = sendingError ? 'Try send again' : 'Send new message';


    // Add click event listener to switch state;
    submitButton.addEventListener('click', function () {
      if (sendingError) {
        //Send Audio
        sendAudio();

        setTimeout(function () {
          step = 5;
          getCorrectHTML();
        }, 5000);


      } else {
        clearValues();
        step = 1;
        getCorrectHTML()
      }
    });

    if (!sendingError) { step5.appendChild(step5text) }
    step5.appendChild(submitButton)


    return step5
  }

  function getAttachButton() {

    let attachButton = document.createElement('div');
    attachButton.className = 'voxMail attachButton';
    let attachButtonInnerDiv = document.createElement('div');
    attachButtonInnerDiv.className = 'voxMail height100 width100 relative attachButtonDiv';

    let attachLabel = document.createElement('label');
    attachLabel.className = 'voxMail pointer';
    attachLabel.setAttribute("for", "imageInput");
    attachLabel.innerHTML = `<svg class='voxMail attachIcon' xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
    <path d="M13.7497 4.99992V14.5833C13.7497 16.4249 12.258 17.9166 10.4163 17.9166C8.57467 17.9166 7.08301 16.4249 7.08301 14.5833V4.16658C7.08301 3.01659 8.01634 2.08325 9.16634 2.08325C10.3163 2.08325 11.2497 3.01659 11.2497 4.16658V12.9166C11.2497 13.3749 10.8747 13.7499 10.4163 13.7499C9.95801 13.7499 9.58301 13.3749 9.58301 12.9166V4.99992H8.33301V12.9166C8.33301 14.0666 9.26634 14.9999 10.4163 14.9999C11.5663 14.9999 12.4997 14.0666 12.4997 12.9166V4.16658C12.4997 2.32492 11.008 0.833252 9.16634 0.833252C7.32467 0.833252 5.83301 2.32492 5.83301 4.16658V14.5833C5.83301 17.1166 7.88301 19.1666 10.4163 19.1666C12.9497 19.1666 14.9997 17.1166 14.9997 14.5833V4.99992H13.7497Z" fill="#3E363F"/>
    </svg>`;

    attachButtonInnerDiv.appendChild(attachLabel);

    imageInput = document.createElement('input');
    imageInput.id = 'imageInput';
    imageInput.className = 'voxMail_textInput voxMail_primary voxMail_body2';
    imageInput.setAttribute('type', 'file');
    imageInput.setAttribute('multiple', true)
    imageInput.setAttribute('accept', '.pdf, image/*');

    // Set the maximum file size (in bytes)
    const MAX_FILE_SIZE = 5242880; // 5 MB

    imageInput.addEventListener("change", function (event) {
      const files = imageInput.files;
      var valid = true;

      for (const file of files) {
        // files.forEach(file => {
        if (file.size > MAX_FILE_SIZE) {
          // File is too big
          showToast("File size exceeds the limit of 5 MB.");
          // Reset the input to clear the selected file
          valid = false;
        }
      }
      // );

      if (valid) {
        // File is within size limits
        for (const file of files) {
          if (file && attachFiles.every(({ name }) => file.name !== name)) {
            attachFiles.push(file);
          }
        }
        getCorrectHTML();
      }
    });

    attachButtonInnerDiv.appendChild(imageInput);

    if (attachFiles.length > 0) {
      let attachCircle = document.createElement('div');
      attachCircle.className = 'voxMail attachCircle voxMailText captionMini white';
      attachCircle.innerHTML = attachFiles.length;

      attachButtonInnerDiv.appendChild(attachCircle);
    }

    attachButton.appendChild(attachButtonInnerDiv);
    return attachButton;
  }

  function getToggleHTML() {

    let toggle = document.createElement('div');

    function isForAnalysis() {
      const urlParams = new URLSearchParams(window.location.search);
      const mode = urlParams.get('mode');

      return mode === 'analysis';
    }

    let togglePicker = document.createElement('div');
    togglePicker.className = `voxMail_TogglePicker ${isForAnalysis() ? "disabled" : ""}`;

    let toggleSpeech = document.createElement('span');
    toggleSpeech.className = `voxMail_ToggleText ${toggleValue === "speech" ? "selected" : ""}`;
    toggleSpeech.innerHTML = "Speak";

    let toggleText = document.createElement('span');
    toggleText.className = `voxMail_ToggleText ${toggleValue === "text" ? "selected" : ""}`;
    toggleText.innerHTML = "Write";

    toggleSpeech.addEventListener('click', function () {
      if (toggleValue !== "speech") {
        toggleValue = "speech";
        getCorrectHTML();
      }
    });

    toggleText.addEventListener('click', function () {
      if (toggleValue !== "text" && !isForAnalysis()) {
        clearInterval(interval);
        currentSeconds = 0;
        toggleValue = "text";
        getCorrectHTML();
      }
    });

    togglePicker.appendChild(toggleSpeech);
    togglePicker.appendChild(toggleText);
    toggle.appendChild(togglePicker);

    return toggle;
  }

  // #region GET CONTENT


  function getContentGap() {

    switch (step) {
      case 1: return "gap48"
      case 2: return toggleValue === "speech" ? "gap20" : "gap74"
      case 3: return toggleValue === "speech" ? "gap56" : "gap74"
      case 4: return "gap69"
      case 5: return "gap20"
      default: return "gap48"
    }
  }

  function getContentText() {

    switch (step) {
      case 1: return ({ title: "Add your contact info", subtitle: "Before sending your message, we need to collect your contact information so that we can reach back out to you. Please enter your details below." })
      case 2: return ({ title: toggleValue === "speech" ? "Start your voice message" : "Write your message", subtitle: toggleValue === "speech" ? `Click the record button to start, stop to finish. <br/>You have <strong>${getMaxRecordingString()}</strong> maximum recording time. <br/>Switch to 'Write' if you prefer to type your message. Use the attach icon to add an image or pdf.` : "Type your message below and then click 'Submit' when you're ready to send it." })
      case 3: return ({ title: toggleValue === "speech" ? "Review before you send" : "Write your message", subtitle: toggleValue === "speech" ? "You have the option to listen to your recording or record again, if you are not happy with the outcome. When you are ready, click send." : "Type out your message and then click 'Submit' to send it on." })
      case 4: return ({ title: "We are sending your message", subtitle: "Your message is currently being uploaded and sent." })
      case 5: return ({ title: sendingError ? "Error sending message" : "Success! Your message has been sent", subtitle: sendingError ? "We're very sorry but something has failed in the sending of your message." : "Your message has been sent to our team via email. We will listen to your message and connect with you as soon as possible." })
      default: return ({ title: "Add your contact info", subtitle: "Before sending your message, we need to collect your contact information so that we can reach back out to you. Please enter your details below." })
    }
  }

  function getRecordingTime() {

    if (injected_subscriptionType === "Small") {
      switch (injected_recordingOption) {
        case 0: return 60;
        case 1: return 90;
        case 2: return 120;
        case 3: return 150;
        default: return 60;
      }
    }
    else if (injected_subscriptionType === "Medium") {
      switch (injected_recordingOption) {
        case 0: return 90;
        case 1: return 120;
        case 2: return 150;
        case 3: return 180;
        default: return 90;
      }
    }
    else if (injected_subscriptionType === "Large") {
      switch (injected_recordingOption) {
        case 0: return 120;
        case 1: return 150;
        case 2: return 180;
        case 3: return 210;
        default: return 120;
      }
    }
  }

  function getMaxRecordingString() {
    let overallSeconds = getRecordingTime()

    let minutes = Math.floor(overallSeconds / 60)
    let seconds = overallSeconds % 60

    let str = minutes + (minutes > 1 ? " minutes" : " minute")

    if (seconds > 0) {
      str += " and " + seconds + " seconds"
    }

    return str

  }

  function replaceButtons() {

    let recordingBtnsRow = document.getElementById('voxMail.recordingBtnsRow');
    recordingBtnsRow.innerHTML = '';

    recordingBtnsRow.appendChild(getRecordingButton('redo'));
    recordingBtnsRow.appendChild(getRecordingButton(currentlyPlayingRecording ? "pause" : "play"));
    recordingBtnsRow.appendChild(getRecordingButton('send'));

  }

  const getRecordingButton = (buttonAction) => {

    var className, recordIcon;

    switch (buttonAction) {
      case "record":
        className = 'voxMail recordButton';
        // text = 'Start Recording';
        recordIcon = `<svg className = ${'voxMail.recordIcon ' + buttonAction} xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 41 40" fill="none">
      <path d="M20.4001 3.3335C11.2001 3.3335 3.7334 10.8002 3.7334 20.0002C3.7334 29.2002 11.2001 36.6668 20.4001 36.6668C29.6001 36.6668 37.0667 29.2002 37.0667 20.0002C37.0667 10.8002 29.6001 3.3335 20.4001 3.3335ZM20.4001 33.3335C13.0334 33.3335 7.06673 27.3668 7.06673 20.0002C7.06673 12.6335 13.0334 6.66683 20.4001 6.66683C27.7667 6.66683 33.7334 12.6335 33.7334 20.0002C33.7334 27.3668 27.7667 33.3335 20.4001 33.3335Z" fill="white"/>
    </svg>`;
        break;
      case "stop":
        className = 'voxMail recordButton';
        // text = 'Stop';
        recordIcon = `<svg className = ${'voxMail.recordIcon ' + buttonAction} xmlns="http://www.w3.org/2000/svg" width="41" height="40" viewBox="0 0 41 40" fill="none">
      <path d="M13.7332 10H27.0666C28.8999 10 30.3999 11.5 30.3999 13.3333V26.6667C30.3999 28.5 28.8999 30 27.0666 30H13.7332C11.8999 30 10.3999 28.5 10.3999 26.6667V13.3333C10.3999 11.5 11.8999 10 13.7332 10Z" fill="white"/>
    </svg>`;
        break;
      case "play":
        className = 'voxMail recordButton black';
        // text = 'Play';
        recordIcon = `<svg className = ${'voxMail.recordIcon ' + buttonAction} xmlns="http://www.w3.org/2000/svg" width="33" height="32" viewBox="0 0 33 32" fill="none">
      <path d="M10.8662 9.09351V22.9068C10.8662 23.9602 12.0262 24.6002 12.9195 24.0268L23.7729 17.1202C24.5995 16.6002 24.5995 15.4002 23.7729 14.8668L12.9195 7.97351C12.0262 7.40017 10.8662 8.04017 10.8662 9.09351Z" fill="white"/>
    </svg>`;
        break;
      case "pause":
        className = 'voxMail recordButton black';
        // text = 'Pause';
        recordIcon = `<svg className = ${'voxMail.recordIcon ' + buttonAction} xmlns="http://www.w3.org/2000/svg" width="33" height="32" viewBox="0 0 33 32" fill="none">
      <path d="M11.0666 25.3332C12.5332 25.3332 13.7332 24.1332 13.7332 22.6665V9.33317C13.7332 7.8665 12.5332 6.6665 11.0666 6.6665C9.5999 6.6665 8.3999 7.8665 8.3999 9.33317V22.6665C8.3999 24.1332 9.5999 25.3332 11.0666 25.3332ZM19.0666 9.33317V22.6665C19.0666 24.1332 20.2666 25.3332 21.7332 25.3332C23.1999 25.3332 24.3999 24.1332 24.3999 22.6665V9.33317C24.3999 7.8665 23.1999 6.6665 21.7332 6.6665C20.2666 6.6665 19.0666 7.8665 19.0666 9.33317Z" fill="white"/>
    </svg>`;
        break;
      case "redo":
        className = 'voxMail recordButton black';
        // text = 'Redo';
        recordIcon = `<svg className = ${'voxMail.recordIcon ' + buttonAction} xmlns="http://www.w3.org/2000/svg" width="23" height="25" viewBox="0 0 23 25" fill="none">
      <path d="M11.4002 3.66671V1.28004C11.4002 0.680041 10.6802 0.386707 10.2668 0.813374L6.5335 4.53337C6.26683 4.80004 6.26683 5.21337 6.5335 5.48004L10.2535 9.20004C10.6802 9.61337 11.4002 9.32004 11.4002 8.72004V6.33337C15.8135 6.33337 19.4002 9.92004 19.4002 14.3334C19.4002 17.96 16.9602 21.0267 13.6535 22C13.0935 22.16 12.7335 22.6934 12.7335 23.2667C12.7335 24.1334 13.5602 24.8134 14.4002 24.56C18.8268 23.2667 22.0668 19.1867 22.0668 14.3334C22.0668 8.44004 17.2935 3.66671 11.4002 3.66671Z" fill="white"/>
      <path d="M3.40007 14.3334C3.40007 12.5468 3.98673 10.8934 4.98673 9.54678C5.38673 9.01345 5.3334 8.28012 4.86673 7.80012C4.30673 7.24012 3.34673 7.29345 2.86673 7.93345C1.5334 9.72012 0.733398 11.9334 0.733398 14.3334C0.733398 19.1868 3.9734 23.2668 8.40007 24.5601C9.24007 24.8134 10.0667 24.1335 10.0667 23.2668C10.0667 22.6935 9.70673 22.1601 9.14673 22.0001C5.84007 21.0268 3.40007 17.9601 3.40007 14.3334Z" fill="white"/>
      </svg>`;
        break;
      case "send":
        className = 'voxMail recordButton';
        // text = 'Submit';
        recordIcon = `<svg className = ${'voxMail.recordIcon ' + buttonAction} xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26" fill="none">
      <path d="M3.64193 21.75L21.819 13.9584C22.6628 13.5938 22.6628 12.4063 21.819 12.0417L3.64193 4.25004C2.95443 3.94795 2.19401 4.45837 2.19401 5.19795L2.18359 10C2.18359 10.5209 2.56901 10.9688 3.08984 11.0313L17.8086 13L3.08984 14.9584C2.56901 15.0313 2.18359 15.4792 2.18359 16L2.19401 20.8021C2.19401 21.5417 2.95443 22.0521 3.64193 21.75V21.75Z" fill="white"/>
    </svg>`;
        break;
      default:
        className = 'voxMail recordButton';
        // text = 'Submit';
        recordIcon = `<svg className = ${'voxMail.recordIcon ' + buttonAction} xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26" fill="none">
      <path d="M3.64193 21.75L21.819 13.9584C22.6628 13.5938 22.6628 12.4063 21.819 12.0417L3.64193 4.25004C2.95443 3.94795 2.19401 4.45837 2.19401 5.19795L2.18359 10C2.18359 10.5209 2.56901 10.9688 3.08984 11.0313L17.8086 13L3.08984 14.9584C2.56901 15.0313 2.18359 15.4792 2.18359 16L2.19401 20.8021C2.19401 21.5417 2.95443 22.0521 3.64193 21.75V21.75Z" fill="white"/>
    </svg>`;
        break;

    }

    let recordButtonSection = document.createElement('div');
    recordButtonSection.className = 'voxMail.recordButtonSection';

    let actionButton = document.createElement('div');
    actionButton.className = className;
    actionButton.id = 'voxMail.recordButton.' + buttonAction;
    actionButton.addEventListener('click', function () {
      if (buttonAction === "record") { startRecording() }
      if (buttonAction === "play") { playRecording() }
      if (buttonAction === "pause") { recordingPause() }
      if (buttonAction === "redo") { redoPressed() }
      if (buttonAction === "send") {
        //Send Audio
        sendAudio();

        recordedAudio.pause();
        currentlyPlayingRecording = false;

        setTimeout(function () {
          step = 5;
          getCorrectHTML();
        }, 5000);
      }
    });

    actionButton.innerHTML = recordIcon;

    return actionButton;
  }


  function getThemeSVG(size) {

    if (injected_theme === "Custom") {

      let colour1 = injected_theme_Colour1
      let colour2 = injected_theme_Colour2
      let colour3 = injected_theme_Colour3
      let colour4 = injected_theme_Colour4

      return `<svg class="${size === 80 ? 'voxMail svgLogoClosed' : ''}" xmlns="http://www.w3.org/2000/svg" width="${size}" height="${size}" viewBox="0 0 241 241" fill="none">
    <circle cx="120.5" cy="120.909" r="106.909" fill="${colour2}"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M120.5 240C186.774 240 240.5 186.274 240.5 120C240.5 53.7258 186.774 0 120.5 0C54.2258 0 0.5 53.7258 0.5 120C0.5 186.274 54.2258 240 120.5 240ZM187.519 168.454C186.211 170.24 185.522 172.417 185.695 174.624L186.939 190.534C187.418 196.663 181.578 201.353 175.697 199.563L160.496 194.937C158.371 194.29 156.088 194.497 154.058 195.397C143.706 199.986 132.237 202.537 120.168 202.537C74.1946 202.537 36.9261 165.52 36.9261 119.858C36.9261 74.1952 74.1946 37.1785 120.168 37.1785C166.141 37.1785 203.409 74.1952 203.409 119.858C203.409 138.019 197.513 154.813 187.519 168.454Z" fill="${colour1}" strokeWidth='0.3'/>
    <circle cx="78.4092" cy="120" r="16" fill="${colour3}"/>
    <rect x="132.409" y="66" width="16" height="108" rx="8" fill="${colour4}"/>
    <rect x="106.409" y="92" width="16" height="55" rx="8" fill="${colour4}"/>
    <rect x="158.409" y="84" width="16" height="72" rx="8" fill="${colour4}"/>
    </svg>`;
    }
    else if (injected_theme === "Light") {
      return `<svg class="${size === 80 ? 'voxMail svgLogoClosed' : ''}" xmlns="http://www.w3.org/2000/svg" width="${size}" height="${size}" viewBox="0 0 241 241" fill="none">
    <circle cx="120.5" cy="120.909" r="106.909" fill="#3E363F"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M120.5 240C186.774 240 240.5 186.274 240.5 120C240.5 53.7258 186.774 0 120.5 0C54.2258 0 0.5 53.7258 0.5 120C0.5 186.274 54.2258 240 120.5 240ZM187.519 168.454C186.211 170.24 185.522 172.417 185.695 174.624L186.939 190.534C187.418 196.663 181.578 201.353 175.697 199.563L160.496 194.937C158.371 194.29 156.088 194.497 154.058 195.397C143.706 199.986 132.237 202.537 120.168 202.537C74.1946 202.537 36.9261 165.52 36.9261 119.858C36.9261 74.1952 74.1946 37.1785 120.168 37.1785C166.141 37.1785 203.409 74.1952 203.409 119.858C203.409 138.019 197.513 154.813 187.519 168.454Z" fill="white" stroke='#3E363F' strokeWidth='0.3'/>
    <circle cx="78.4092" cy="120" r="16" fill="#E34624"/>
    <rect x="132.409" y="66" width="16" height="108" rx="8" fill="white"/>
    <rect x="106.409" y="92" width="16" height="55" rx="8" fill="white"/>
    <rect x="158.409" y="84" width="16" height="72" rx="8" fill="white"/>
    </svg>`;
    }
    else if (injected_theme === "Grey") {
      return `<svg class="${size === 80 ? 'voxMail svgLogoClosed' : ''}" xmlns="http://www.w3.org/2000/svg" width="${size}" height="${size}" viewBox="0 0 241 241" fill="none">
    <circle cx="120.5" cy="120.909" r="106.909" fill="white"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M120.5 240C186.774 240 240.5 186.274 240.5 120C240.5 53.7258 186.774 0 120.5 0C54.2258 0 0.5 53.7258 0.5 120C0.5 186.274 54.2258 240 120.5 240ZM187.519 168.454C186.211 170.24 185.522 172.417 185.695 174.624L186.939 190.534C187.418 196.663 181.578 201.353 175.697 199.563L160.496 194.937C158.371 194.29 156.088 194.497 154.058 195.397C143.706 199.986 132.237 202.537 120.168 202.537C74.1946 202.537 36.9261 165.52 36.9261 119.858C36.9261 74.1952 74.1946 37.1785 120.168 37.1785C166.141 37.1785 203.409 74.1952 203.409 119.858C203.409 138.019 197.513 154.813 187.519 168.454Z" fill="#909090"/>
    <circle cx="78.4092" cy="120" r="16" fill="#E34624"/>
    <rect x="132.409" y="66" width="16" height="108" rx="8" fill="#909090"/>
    <rect x="106.409" y="92" width="16" height="55" rx="8" fill="#909090"/>
    <rect x="158.409" y="84" width="16" height="72" rx="8" fill="#909090"/>
    </svg>`;
    }
    else if (injected_theme === "Dark") {
      return `<svg class="${size === 80 ? 'voxMail svgLogoClosed' : ''}" xmlns="http://www.w3.org/2000/svg" width="${size}" height="${size}" viewBox="0 0 241 241" fill="none">
    <circle cx="120.5" cy="120.909" r="106.909" fill="white"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M120.5 240C186.774 240 240.5 186.274 240.5 120C240.5 53.7258 186.774 0 120.5 0C54.2258 0 0.5 53.7258 0.5 120C0.5 186.274 54.2258 240 120.5 240ZM187.519 168.454C186.211 170.24 185.522 172.417 185.695 174.624L186.939 190.534C187.418 196.663 181.578 201.353 175.697 199.563L160.496 194.937C158.371 194.29 156.088 194.497 154.058 195.397C143.706 199.986 132.237 202.537 120.168 202.537C74.1946 202.537 36.9261 165.52 36.9261 119.858C36.9261 74.1952 74.1946 37.1785 120.168 37.1785C166.141 37.1785 203.409 74.1952 203.409 119.858C203.409 138.019 197.513 154.813 187.519 168.454Z" fill="#3E363F"/>
    <circle cx="78.4092" cy="120" r="16" fill="#E34624"/>
    <rect x="132.409" y="66" width="16" height="108" rx="8" fill="#3E363F"/>
    <rect x="106.409" y="92" width="16" height="55" rx="8" fill="#3E363F"/>
    <rect x="158.409" y="84" width="16" height="72" rx="8" fill="#3E363F"/>
    </svg>`;
    }
    else if (injected_theme === "Red") {
      return `<svg class="${size === 80 ? 'voxMail svgLogoClosed' : ''}" xmlns="http://www.w3.org/2000/svg" width="${size}" height="${size}" viewBox="0 0 241 241" fill="none">
    <circle cx="120.5" cy="120.909" r="106.909" fill="white"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M120.5 240C186.774 240 240.5 186.274 240.5 120C240.5 53.7258 186.774 0 120.5 0C54.2258 0 0.5 53.7258 0.5 120C0.5 186.274 54.2258 240 120.5 240ZM187.519 168.454C186.211 170.24 185.522 172.417 185.695 174.624L186.939 190.534C187.418 196.663 181.578 201.353 175.697 199.563L160.496 194.937C158.371 194.29 156.088 194.497 154.058 195.397C143.706 199.986 132.237 202.537 120.168 202.537C74.1946 202.537 36.9261 165.52 36.9261 119.858C36.9261 74.1952 74.1946 37.1785 120.168 37.1785C166.141 37.1785 203.409 74.1952 203.409 119.858C203.409 138.019 197.513 154.813 187.519 168.454Z" fill="#D73907"/>
    <circle cx="78.4092" cy="120" r="16" fill="#262126"/>
    <rect x="132.409" y="66" width="16" height="108" rx="8" fill="#3E363F"/>
    <rect x="106.409" y="92" width="16" height="55" rx="8" fill="#3E363F"/>
    <rect x="158.409" y="84" width="16" height="72" rx="8" fill="#3E363F"/>
    </svg>`;
    }
    else {
      return `<svg class="${size === 80 ? 'voxMail svgLogoClosed' : ''}" xmlns="http://www.w3.org/2000/svg" width="${size}" height="${size}" viewBox="0 0 241 241" fill="none">
    <circle cx="120.5" cy="120.909" r="106.909" fill="white"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M120.5 240C186.774 240 240.5 186.274 240.5 120C240.5 53.7258 186.774 0 120.5 0C54.2258 0 0.5 53.7258 0.5 120C0.5 186.274 54.2258 240 120.5 240ZM187.519 168.454C186.211 170.24 185.522 172.417 185.695 174.624L186.939 190.534C187.418 196.663 181.578 201.353 175.697 199.563L160.496 194.937C158.371 194.29 156.088 194.497 154.058 195.397C143.706 199.986 132.237 202.537 120.168 202.537C74.1946 202.537 36.9261 165.52 36.9261 119.858C36.9261 74.1952 74.1946 37.1785 120.168 37.1785C166.141 37.1785 203.409 74.1952 203.409 119.858C203.409 138.019 197.513 154.813 187.519 168.454Z" fill="#3E363F"/>
    <circle cx="78.4092" cy="120" r="16" fill="#E34624"/>
    <rect x="132.409" y="66" width="16" height="108" rx="8" fill="#3E363F"/>
    <rect x="106.409" y="92" width="16" height="55" rx="8" fill="#3E363F"/>
    <rect x="158.409" y="84" width="16" height="72" rx="8" fill="#3E363F"/>
    </svg>`;
    }
  }

  const recordingFunction = function (stream) {
    interval = setInterval(incrementCurrentTime, 1000);
    streamG = stream;

    document.getElementById("voxMail_redLineRecord").className = "voxMail redLineRecord moving";
    document.getElementById("voxMail_redBoxRecord").className = "voxMail redBoxRecord moving";

    const recordTime = getRecordingTime();
    ["voxMail_redLineRecord", "voxMail_redBoxRecord"].forEach(id => {
      let element = document.getElementById(id);
      element.style.animationDuration = recordTime + 's';
      element.style.animationPlayState = 'running';
      element.addEventListener("webkitAnimationEnd", stopRecording);
    });

    const audioContext = new AudioContext();
    const source = audioContext.createMediaStreamSource(stream);
    const analyser = audioContext.createAnalyser();
    analyser.fftSize = 256; // Lower for performance, increase for detail
    source.connect(analyser);
    drawRecordingOnCanvas(document.getElementById("voxMail_canvas"), analyser);

    let options;
    if (MediaRecorder.isTypeSupported('audio/webm; codecs=opus')) {
      options = { mimeType: 'audio/webm; codecs=opus' };
      activeMimeType = 'audio/webm; codecs=opus';
    } else if (MediaRecorder.isTypeSupported('audio/mp4')) {
      options = { mimeType: 'audio/mp4' }; // Fallback for Safari
      activeMimeType = 'audio/mp4';
      console.warn('Your browser does not support Opus, using MP4 as fallback');
    } else {
      console.error('Your browser does not support audio recording');
      // Handle the error or provide a fallback
    }

    mediaRecorder = new MediaRecorder(stream, options);

    mediaRecorder.addEventListener('dataavailable', e => {
      if (e.data.size > 0) recordedChunks.push(e.data);
    });

    mediaRecorder.addEventListener('stop', () => {
      let blob = new Blob(recordedChunks, { type: activeMimeType }); // Dynamically use the mimeType
      recordedAudioURL = URL.createObjectURL(blob);
      recordedAudio = new Audio(recordedAudioURL);
    });

    document.getElementById("voxMail.recordButton.stop").addEventListener('click', stopRecording);

    mediaRecorder.start();
  };

  const stopRecording = () => {

    let redLine = document.getElementById("voxMail_redLineRecord");
    let redBox = document.getElementById("voxMail_redBoxRecord");

    if (redLine != null) {
      redLine.style.animationPlayState = 'running';
      redBox.style.animationPlayState = 'running';
    }

    if (mediaRecorder !== undefined) { mediaRecorder.stop(); }

    //Turn off mic
    if (streamG !== undefined) {
      const tracks = streamG.getTracks();
      tracks.forEach(track => {
        track.stop();
      });
    }

    currentlyRecording = false;
    step = 3;

    clearInterval(interval);

    getCorrectHTML();
  }

  const playRecording = () => {

    if (Math.abs(recordedAudio.currentTime - recordedAudioDuration) <= 1) {
      currentSeconds = 0;
    } else {
      currentSeconds = Math.floor(recordedAudio.currentTime);
      console.log(recordedAudio.currentTime);
    }

    let currentTime = document.getElementById('currentTimeText');
    currentTime.innerHTML = currentSeconds === 0 ? "00:00" : getTimeString(currentSeconds);

    interval = setInterval(incrementCurrentTime, 1000);

    currentlyPlayingRecording = true;
    replaceButtons();
    showEndTime();

    recordedAudio.play();
    let redLine = document.getElementById("voxMail_redLineRecord");

    redLine.style.animationDuration = recordedAudioDuration + 's';
    redLine.style.animationPlayState = 'running';

    recordedAudio.addEventListener("ended", function () {

      redLine.style.animationPlayState = 'paused';
      redLine.style.animation = 'none';
      setTimeout(function () {
        redLine.style.webkitAnimation = '';
      }, 10);
      currentlyPlayingRecording = false;

      clearInterval(interval);
      currentSeconds = Math.floor(recordedAudioDuration);
      getCorrectHTML();
    });
  }

  const recordingPause = () => {

    clearInterval(interval);

    currentSeconds = Math.floor(recordedAudio.currentTime);
    let currentTime = document.getElementById('currentTimeText');
    currentTime.innerHTML = currentSeconds === 0 ? "00:00" : getTimeString(currentSeconds);

    var redLine = document.getElementById("voxMail_redLineRecord");

    recordedAudio.pause();
    currentlyPlayingRecording = false;
    replaceButtons();

    redLine = document.getElementById("voxMail_redLineRecord");
    redLine.style.animationPlayState = 'paused';
  }

  const redoPressed = () => {

    clearInterval(interval);
    currentSeconds = 0;
    recordedAudioDuration = 0;

    recordedAudio.pause();
    currentlyPlayingRecording = false;

    recordedChunks = [];
    // var blob;
    // blob = new Blob(recordedChunks, { "type": 'audio/mp3' });
    recordedAudio = null;
    step = 2;

    getCorrectHTML();
  }

  const incrementCurrentTime = () => {
    currentSeconds += 1;

    if (currentlyRecording) {
      recordedAudioDuration = currentSeconds;
    }
    let span = document.getElementById('currentTimeText');

    let timeStr = getTimeString(currentSeconds);
    span.innerHTML = timeStr;
  }

  function drawRecordedAudioOnCanvas(canvas) {

    var ctx = canvas.getContext("2d");

    const lineThickness = 1; // Drawing width for each line
    const gap = 2; // Gap between each line
    const combinedWidth = lineThickness + gap; // The combined width of a line and its subsequent gap
    const totalLines = 320 / combinedWidth; // Total number of lines we want to draw on the canvas

    // Find the maximum height
    var max_height = Math.max.apply(null, lineHeights);

    if (lineHeights.length < totalLines) {
      // Interpolate for cases where we have fewer data points than totalLines
      let lastX = 0, lastSize = lineHeights[0];

      for (let i = 1; i < lineHeights.length; i++) {
        let nextX = Math.floor(i * totalLines / lineHeights.length) * combinedWidth;
        let nextSize = lineHeights[i];
        let step = (nextSize - lastSize) / (nextX - lastX);

        for (let x = lastX; x < nextX; x += combinedWidth) {
          let interpolatedSize = lastSize + step * (x - lastX);
          let size = (interpolatedSize / max_height) * 32;

          ctx.moveTo(x, 32 - size);
          ctx.lineTo(x, 32 + size);
          ctx.stroke();
        }

        lastX = nextX;
        lastSize = nextSize;
      }
    } else {
      // Use averaging for cases where we have more data points than totalLines
      var scale = lineHeights.length / totalLines;

      for (var i = 0; i < totalLines; i++) {
        var startIndex = Math.floor(i * scale);
        var endIndex = Math.floor((i + 1) * scale);

        // Compute the average height from lineHeights for this line
        var sum = 0;
        for (var j = startIndex; j < endIndex; j++) {
          sum += lineHeights[j];
        }
        var avgSize = sum / (endIndex - startIndex);

        // Scale the line height relative to the maximum height
        var size = (avgSize / max_height) * 32;

        var x = i * combinedWidth; // Adjusted x position to include gap
        ctx.moveTo(x, 32 - size);
        ctx.lineTo(x, 32 + size);
        ctx.stroke();
      }
    }
  }

  function drawRecordingOnCanvas(canvas, analyser) {

    const refreshRate = 1000 / 30; // Capture frequency data 30 times a second
    const lineThickness = 1;
    const gap = 2;
    const combinedWidth = lineThickness + gap;
    let maxDataAvailable = 0;

    // Calculate how many frequency bins will be represented on the canvas
    const numBins = Math.floor(320 / combinedWidth);
    var dataArray = new Uint8Array(analyser.frequencyBinCount); // Using full frequency bin count

    // Compute how many frequency bins we'll average for each line on the canvas
    var binsPerLine = Math.floor(analyser.frequencyBinCount / numBins);

    var ctx = canvas.getContext("2d");

    // Initialize the canvas with vertical lines across its width
    for (let i = 0; i < 320; i += combinedWidth) {
      ctx.moveTo(i, 32);
      ctx.lineTo(i, 32); // Starting with zero height
      ctx.stroke();
    }

    lineHeights = [];
    lineHeights.length = 0; // Clear previous data

    var intervalId = window.setInterval(function () {
      if (currentlyRecording) {
        // Get frequency data
        analyser.getByteFrequencyData(dataArray);

        let highestNonZeroInRun = 0;
        for (let i = 0; i < dataArray.length; i++) {
          if (dataArray[i] > 0) {
            highestNonZeroInRun = i;
          }
        }

        // Clear the canvas to draw new heights
        ctx.clearRect(0, 0, canvas.width, canvas.height);
        let lastLineHeight = 0;

        // Draw each line based on the volume of its frequency bin
        // Inside your setInterval function
        for (let i = 0; i < numBins; i++) {
          let x = i * combinedWidth;

          // Average the bins for this line
          let startBin = Math.floor(i * binsPerLine);
          let endBin = Math.floor((i + 1) * binsPerLine);
          let sumVolume = 0;
          for (let j = startBin; j < endBin; j++) {
            sumVolume += dataArray[j];
          }
          let volume = sumVolume / (endBin - startBin);
          var height = 0;
          // Mapping the volume to a height.
          if (startBin === endBin) {
            // Make the height some value either 10 bigger or smaller than the last recorded height
            height = lastLineHeight + ((Math.random() * 10 - 5) * (lastLineHeight / 32));
          }
          else {
            height = (volume / 255) * 32;
            lastLineHeight = height;
          }

          ctx.beginPath(); // Start a new path for this line
          ctx.moveTo(x, 32 - height);
          ctx.lineTo(x, 32 + height);
          ctx.stroke();
          ctx.closePath();
        }

        if (highestNonZeroInRun > maxDataAvailable) {
          maxDataAvailable = highestNonZeroInRun;
          binsPerLine = maxDataAvailable / numBins;
        }

        // Map the volume to a height between 5 and 32 with a logarithmic function
        let volume = dataArray.reduce((a, b) => a + b) / dataArray.length;
        let normalized_volume = volume / 100;
        normalized_volume = Math.min(normalized_volume, 1); // Ensure volume is never > 1
        normalized_volume = Math.max(normalized_volume, 0.01); // Ensure volume is never 0
        let size = Math.log1p(normalized_volume) * 50.4943;

        lineHeights.push(size);
      }
      else {
        window.clearInterval(intervalId);
      }
    }, refreshRate);
  }

  const startRecording = () => {

    currentlyRecording = true;
    navigator.mediaDevices.getUserMedia({ audio: true, video: false })
      .then(stream => {

        recordingFunction(stream);
      })
      .catch(error => {
        showError(error.name, error);
      });

    getCorrectHTML();
  }

  // #endregion

  // #region WEB REQUESTS

  function sendAudio() {

    sendLog(toggleValue === "speech" ? "messageSentVoice" : "messageSentText")

    step = 4;
    getCorrectHTML();
    clearInterval(interval);
    currentSeconds = 0;
    sendingError = false;

    // Function to get the endpoint based on URL parameters
    function getEndpoint() {
      const urlParams = new URLSearchParams(window.location.search);
      const mode = urlParams.get('mode');

      if (mode === 'analysis') {
        return 'https://us-central1-vcml-7b6cd.cloudfunctions.net/vcml_processAudio_analysis';
      }
      return 'https://vcml-processaudio-sendvoicemail-v7-345725459212.us-central1.run.app';
    }

    var xhr_get_audio = new XMLHttpRequest();
    xhr_get_audio.open('GET', recordedAudioURL, true);
    if (!xhr_get_audio) { console.error('Failed to create request'); }
    xhr_get_audio.responseType = 'blob';
    xhr_get_audio.onload = function (e) {
      if (this.status === 200) {
        var blob = this.response;

        var xhr_send = new XMLHttpRequest();
        var fileExtension = activeMimeType === "audio/mp4" ? "mp4" : "webm";
        var filename = generateTempFileName(fileExtension);

        // Keep minimal error handling
        xhr_send.onerror = function (e) {
          console.error('Error occurred:', e);
          sendingError = true
        };

        xhr_send.onload = function (e) {
          if (this.status === 200) {
            var res = this.response;
            let json = JSON.parse(res)
            console.log("RES",json)
            sending(false)
            addMessage(json)
          }
        };

        if (!injected_isContactPageScript) {
          currentLocation = window.location.pathname;
        }

        var fd = new FormData();
        fd.append("serviceID", serviceID);
        fd.append("userEmail", emailInputVal);
        fd.append("userName", nameInputVal);
        fd.append("userPhone", phoneInputVal);
        fd.append("userDropdown", dropdownInputVal);
        fd.append("textSubmission", textSubmission);
        fd.append("submissionType", toggleValue);
        fd.append("currentLocation", currentLocation);

        fd.append("newThread", false);
        fd.append("senderID",viewer );
        fd.append("threadID", doc);
        fd.append("secretKey", key);
        fd.append("senderLanguage", propsData.senderLanguage);
        fd.append("receiverLanguage", propsData.receiverLanguage);

        fd.append("audioBlob", blob, filename);

        for (var i = 0; i < attachFiles.length; i++) {
          fd.append("attachments", attachFiles[i], attachFiles[i].name);
        }

        // Get the appropriate endpoint based on URL parameters
        const endpoint = getEndpoint();
        xhr_send.open("POST", endpoint, true);

        // Send the request without waiting for a response
        xhr_send.send(fd);

      } else {
        console.error(e);
      }
    };
    xhr_get_audio.send();
    sending(true)
  }

  function sendLog(action) {

    var params = `serviceID=${serviceID}&action=${action}`;

    var xhr_sendLog = new XMLHttpRequest();
    xhr_sendLog.open('GET', 'https://us-central1-vcml-7b6cd.cloudfunctions.net/logBasicWidgetAction?' + params, true);

    xhr_sendLog.send();
  }

  // #endregion

  // #region TOOLS

  function generateTempFileName(extension = '') {
    // Prefix for the temporary file to easily identify it
    const prefix = 'tmp_';

    // Generate a random sequence of characters
    // Here, we're using a combination of the current timestamp and a random number for uniqueness
    const randomSequence = Date.now().toString(36) + Math.random().toString(36).substring(2, 15);

    // Append the file extension if provided
    const fileName = `${prefix}${randomSequence}${extension ? '.' + extension : ''}`;

    return fileName;
  }

  function validateEmail(email) {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  }

  function clearValues() {
    currentSeconds = 0;
    recordedAudioDuration = 0;
    stopRecording();
    // apiError = false;
    recordedAudio = null;
    recordedChunks = [];
    textSubmission = "";
    toggleValue = "speech";
    attachFiles = []
  }

  // #endregion

  function showSpeechBubblePrompt(colour, title, subtitle, appearAfterMs, dismissAfterMs) {
    // Create the speech bubble element
    const speechBubble = document.createElement('div');
    speechBubble.className = `voxMail speechBubble ${injected_position === "right" ? "" : "left"} ${colour}`;

    const speechBubbleTitle = document.createElement('span')
    speechBubbleTitle.className = 'voxMailText alignLeft subtitle2'
    speechBubbleTitle.innerHTML = title || stringCollection.promptTitle
    speechBubble.appendChild(speechBubbleTitle)

    const speechBubbleSubTitle = document.createElement('span')
    speechBubbleSubTitle.className = 'voxMailText alignLeft body2'
    speechBubbleSubTitle.innerHTML = subtitle || stringCollection.promptSubTitle
    speechBubble.appendChild(speechBubbleSubTitle)

    // Initially set the speech bubble to be invisible to calculate its dimensions
    speechBubble.style.visibility = 'hidden';

    // Create a dismiss button for the speech bubble
    const dismissBtn = document.createElement('span');
    dismissBtn.className = `voxMail dismissBtn ${colour}`;
    dismissBtn.innerHTML = '&times;';
    dismissBtn.onclick = () => speechBubble.remove(); // Remove bubble when dismissed
    speechBubble.appendChild(dismissBtn);

    const positionAndShowBubble = () => {
      const button = document.querySelector('.voxMail.imageContainer'); // Replace with your button's selector
      if (button) {
        speechBubble.style.visibility = 'visible'; // Make the speech bubble visible
      }
    };

    // Timeout to position and show the speech bubble after the specified time
    setTimeout(positionAndShowBubble, appearAfterMs);

    // Auto-dismiss timeout
    setTimeout(() => {
      if (speechBubble && speechBubble.parentNode) {
        speechBubble.remove();
      }
    }, appearAfterMs + dismissAfterMs);

    // Return the speech bubble in case we need to reference it later
    return speechBubble;
  }

  function showToast(message) {
    // Create the toast element
    var toast = document.createElement('div');
    toast.className = 'voxMailErrorToast';
    toast.innerText = message;

    // Add the toast to the body
    document.body.appendChild(toast);

    // Show the toast
    setTimeout(function () {
      toast.className = 'voxMailErrorToast show';
    }, 100); // Delay to allow the element to be rendered in the DOM

    // Hide the toast after 3 seconds and remove it from the DOM
    setTimeout(function () {
      toast.className = 'voxMailErrorToast';
      setTimeout(function () {
        document.body.removeChild(toast);
      }, 500); // Allow time for fade out animation
    }, 3000);
  }

  // const getTimeText = () => {

  //   let recordTime = getRecordingTime();

  //   let minutes = Math.floor(recordTime / 60);
  //   var seconds = recordTime % 60;

  //   var str = "";
  //   if (minutes > 0) {
  //     str += minutes;
  //     str += minutes > 1 ? " minutes" : " minute";
  //   }
  //   if (seconds > 0) {
  //     if (minutes > 0) { str += " and " }
  //     str += seconds;
  //     str += seconds > 1 ? " seconds" : " second";
  //   }
  //   return str;
  // }

  const showError = (message, fullError) => {

    let alertMessage = 'An error occurred while trying to access the microphone.';

    switch (message) {
      case 'NotAllowedError':
        console.error('Microphone access was denied.');
        alertMessage = 'Microphone access was denied. Please allow access to record.';
        break;
      case 'NotFoundError':
        console.error('No microphone detected.');
        alertMessage = 'No microphone detected on your device.';
        break;
      default:
        console.error('An error occurred:', message, fullError);
    }
    // You can display the error message in a dialog, toast, or any other way you prefer
    alert(alertMessage);
    currentlyRecording = false;
    getCorrectHTML();
  }

  function showEndTime() {

    let timeRow = document.getElementById('voxMail_timeRow');
    timeRow.innerHTML = '';

    let time1 = document.createElement('span');
    time1.innerText = '00:00';
    time1.className = 'voxMailText primary captionMini';
    timeRow.appendChild(time1);

    if (recordedAudio !== null) {
      let time2 = document.createElement('span');
      var time = getTimeString(recordedAudioDuration);
      time2.innerText = time;
      time2.className = 'voxMailText primary captionMini';
      timeRow.appendChild(time2);
    }
  }

  // Helper function to load an image
  function loadImage(img, url, addBase = false) {

    if (addBase) {
      let baseUrl = 'https://firebasestorage.googleapis.com/v0/b/vcml-7b6cd.appspot.com/o/';
      url = baseUrl + url;
    }

    return new Promise((resolve, reject) => {
      img.onload = () => {
        resolve(); // Image loaded successfully
      };

      img.onerror = (error) => {
        console.error("Image load failed:", error, url);
        reject(error); // Reject the promise with the error
        // img.src = 'fallback-image-url'; // Optional fallback in case of error
      };

      img.src = url;
    });
  }

  function getTimeString(secondsP) {
    if (secondsP < 0) {
      return '00:00'; // Or handle the error as appropriate
    }

    var minutes = Math.floor(secondsP / 60);
    var seconds = secondsP % 60;

    // Convert minutes and seconds to strings and pad with zeros if necessary
    var minutesStr = minutes < 10 ? '0' + minutes : minutes.toString();
    var secondsStr = seconds < 10 ? '0' + seconds : seconds.toString();

    return minutesStr + ':' + secondsStr;
  }
    

    return(
        <div className="voxMailToolContainer" id='voxMailToolContainer' />
                
    )

}