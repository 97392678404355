
import '../styling/feedback.css'
import '../styling/reply.css'
import '../styling/constants/typography.css'
import React, { Suspense, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Autocomplete, Box } from '@mui/material';
import { CN, DE, ES, FR, GB, IE, IN, JP, PT, RU, SA } from 'country-flag-icons/react/3x2'
import { TextInput } from '../components/mui/Inputs';
import axios from "axios";
import VoxMailTool from '../components/VoxMailTool';
import { Forum, KeyboardReturn } from '@mui/icons-material';
import Moment from 'moment';

export default function Reply() {


    // CAOLAN + CADE
    // <a href="http://localhost:3000/reply?messages=WwogICAgewogICAgICAgICJpZCI6MSwKICAgICAgICAibmFtZSI6IkNhb2xhbiIsCiAgICAgICAgImVtYWlsIjoiY2FvbGFuQHZveC1tYWlsLmNvbSIsCiAgICAgICAgIm1lc3NhZ2UiOiAiSGVsbG8gQ2FkZSwgSSB3b3VsZCBsaWtlIHRvIGdldCBhbiB1cGRhdGUgb24gdGhlIHN0YWNrZWQgZW1haWwgdGhyZWFkIHN0eWxpbmcuIiwKICAgICAgICAidHJhbnNsYXRpb24iOiBudWxsLAogICAgICAgICJsYW5ndWFnZSI6ICJlbiIKICAgIH0sCiAgICB7CiAgICAgICAgImlkIjoyLAogICAgICAgICJuYW1lIjoiQ2FkZSIsCiAgICAgICAgImVtYWlsIjoiY2FkZUB2b3gtbWFpbC5jb20iLAogICAgICAgICJtZXNzYWdlIjogIkhlbGxvIENhb2xhbiwgSSB3YXMgd2FpdGluZyB1bnRpbCB5b3UgaGFkIGRvbmUgc29tZSB3b3JrIG9uIHRoZSBmcm9udGVuZCBzY3JlZW4uIiwKICAgICAgICAidHJhbnNsYXRpb24iOiBudWxsLAogICAgICAgICJsYW5ndWFnZSI6ICJlbiIKICAgIH0sCiAgICB7CiAgICAgICAgImlkIjoxLAogICAgICAgICJuYW1lIjoiQ2FvbGFuIiwKICAgICAgICAiZW1haWwiOiJjYW9sYW5Adm94LW1haWwuY29tIiwKICAgICAgICAibWVzc2FnZSI6ICJXZWxsIGxvb2sgYXQgdGhpcyB3b3JrIHRoYXQgSSBoYXZlIGRvbmUuIiwKICAgICAgICAidHJhbnNsYXRpb24iOiBudWxsLAogICAgICAgICJsYW5ndWFnZSI6ICJlbiIKICAgIH0sCiAgICAKICAgIHsKICAgICAgICAiaWQiOjMsCiAgICAgICAgIm5hbWUiOiJDYWRlIiwKICAgICAgICAiZW1haWwiOiJjYWRlQHZveC1tYWlsLmNvbSIsCiAgICAgICAgIm1lc3NhZ2UiOiAiU2FjcmUgYmxldSEiLAogICAgICAgICJ0cmFuc2xhdGlvbiI6ICJPaCBteSBnb2QsIHRoaXMgd29yayBpcyBhbWF6aW5nLCB0aGUgYmVzdCB0aGluZyBJIGhhdmUgZXZlciBzZWVuLiBJIGFtIHNvIGluc3BpcmVkIGJ5IGl0IHRoYXQgSSBnb2luZyB0byB3b3JrIGxpa2UgY3JhenkgdG9kYXkgdG8gZ2V0IHRoZSBlbWFpbHMgbG9va2luZyBpbmNyZWRpYmxlISIsCiAgICAgICAgImxhbmd1YWdlIjogImZyIgogICAgfQpd" target="_blank"><button>Reply via VoxMail</button></a>


    // CAOLAN + SIMONAS
    // <a href="http://localhost:3000/reply?messages=WwogICAgewogICAgICAgICJpZCI6MSwKICAgICAgICAibmFtZSI6IkNhb2xhbiIsCiAgICAgICAgImVtYWlsIjoiY2FvbGFuQHZveC1tYWlsLmNvbSIsCiAgICAgICAgIm1lc3NhZ2UiOiAiQ2FuIHlvdSBsZXQgbWUga25vdyBpZiB0aGlzIGlzIHdvcmtpbmcgZm9yIHlvdSIsCiAgICAgICAgInRyYW5zbGF0aW9uIjogbnVsbCwKICAgICAgICAibGFuZ3VhZ2UiOiAiZW4iCiAgICB9LAogICAgewogICAgICAgICJpZCI6MiwKICAgICAgICAibmFtZSI6IlNpbW9uYXMiLAogICAgICAgICJlbWFpbCI6InNpbW9uYXNAdm94LW1haWwuY29tIiwKICAgICAgICAibWVzc2FnZSI6ICJZZXMsIHdvcmtpbmcgcGVyZmVjdGx5LiIsCiAgICAgICAgInRyYW5zbGF0aW9uIjogbnVsbCwKICAgICAgICAibGFuZ3VhZ2UiOiAiZW4iCiAgICB9Cl0=" target="_blank"><button>Reply via VoxMail</button></a>

    // <a href="http://localhost:3000/reply?doc=1234&key=caolankey" target="_blank"><button>Reply via VoxMail</button></a>

    const [messagesJSON, setMessagesJSON] = useState(null);
    const [customerData, setCustomerData] = useState(null);
    const [businessData, setBusinessData] = useState(null);

    const [searchParams] = useSearchParams();

    const [recipient, setRecipient] = useState("");
    const [language, setLanguage] = useState('en');
    const languageOptions = ["English", "Spanish", "French", "German", "Portuguese", "Mandarin", "Hindi", "Arabic", "Japanese", "Russian", "Irish"];

    const [loadError, setLoadError] = useState("");
    const [viewerStatus, setViewerStatus] = useState("");

    const [toolProps, setToolProps] = useState({});
    const [openTranslations, setOpenTranslations] = useState([]);
    const [updateVar, setUpdateVar] = useState(0);

    const [sending, setSending] = useState(false);


    const baseURL = process.env.REACT_APP_BASEURL_US
   


    useEffect(() => {

        let key = searchParams.get("key")
        let doc = searchParams.get("doc")
        let user = searchParams.get("viewer")

        loadMessagesFromDB(key,doc,user)
        // let messages = searchParams.get("messages")
        // console.log("messages b64",messages)
        // const jsonString = atob(messages);
        // let json = JSON.parse(jsonString);

        

    }, []);// eslint-disable-line react-hooks/exhaustive-deps

    const loadMessagesFromDB = (key,doc,user) => {
        return new Promise(async (resolve, reject) => {

            console.log("base url", baseURL);

            axios.get(baseURL + 'loadThread', {
                params: {
                    key: key,
                    docID: doc,
                    user: user
                }
            }).then((response) => {

                let resVar = response.data

                if(resVar.error)
                {
                    setLoadError(resVar.errorMessage)
                }
                else{
                    let toolPropsData = resVar.resData.service
                    toolPropsData.senderName = resVar.resData.isCustomer?resVar.resData.customerData.name:resVar.resData.businessData.name
                    toolPropsData.senderEmail = resVar.resData.isCustomer?resVar.resData.customerData.email:resVar.resData.businessData.email
                    toolPropsData.senderPhone = resVar.resData.isCustomer?resVar.resData.customerData.phone:resVar.resData.businessData.phone
                    toolPropsData.senderLanguage = resVar.resData.isCustomer?resVar.resData.customerData.language:resVar.resData.businessData.language
                    toolPropsData.receiverLanguage = resVar.resData.isCustomer?resVar.resData.businessData.language:resVar.resData.customerData.language
                    
                    
                    setToolProps(toolPropsData)

                    let messages = resVar.resData.messages
                    setMessagesJSON(messages)
                    setBusinessData(resVar.resData.businessData)
                    setCustomerData(resVar.resData.customerData)
                    setViewerStatus(resVar.resData.isCustomer?"customer":"business")
            
                    if(messages.length > 0){
                        setRecipient(resVar.resData.isCustomer?resVar.resData.businessData.email:resVar.resData.customerData.email)
                        setLanguage(convertLanguage(messages[messages.length-1].language))
                    }
                }

                
            })

        })
    }

    const showMessages = () => {

        let compArray = []

        if(messagesJSON){
            let index = 0
            messagesJSON.forEach(message => {
                let thisIndex = index
                compArray.push(<div className={`replyChatBubble ${message.sender===viewerStatus?"you":""}`}>

                    <div className='column ai-start alignLeft'>
                        <span className='caption'><strong>{`From: ${message.sender==="customer"?customerData.name:businessData.name} (${message.sender==="customer"?customerData.email:businessData.email})`}</strong></span>
                        {message.timestamp && <span className='caption'>{Moment(message.timestamp).format('dddd, MMM DD, HH:mm')}</span>}
                    </div>

                    <div className='column ai-start gap12'>
                        <span className='body2'>{message.sender!==viewerStatus&&message.translation?message.translation:message.message}</span>

                        {message.sender!==viewerStatus&&message.translation && <span className='caption pointer' onClick={()=>addToOpenTranslation(thisIndex)}>{openTranslations.includes(index)?"Original Message":"Show original"}</span>}
                        {openTranslations.includes(thisIndex) && <span className='body2 italics'>{message.message}</span>}
                    </div>

                    {/* <span className='alignLeft'><strong>Message: </strong>{message.message}</span>
                    <span><strong>Language: </strong>{convertLanguage(message.language)}</span>
                    {message.translation && <span className='alignLeft'><strong>Translations: </strong>{message.translation}</span>} */}

                </div>)
                index++;
            });
        }

        console.log("OPEN",openTranslations)

        return compArray
    }

    const addMessage = (data) => {
        
        let newMessage = {
            id:messagesJSON.length+1,
            language:data.sourceLanguage,
            message: data.transcription,
            sender: viewerStatus,
            timestamp: new Date().getTime(),
            translation:data.translation
        }

        messagesJSON.push(newMessage)

        console.log("NEW MESSAGES",messagesJSON)
        setMessagesJSON(messagesJSON)
        setUpdateVar(updateVar+1)

    }

    const addToOpenTranslation = (index) => {
        openTranslations.push(index)
        setOpenTranslations(openTranslations)
        setUpdateVar(updateVar+1)
    }

    const convertLanguage = (languageCode) => {

        switch(languageCode){
            case 'en': return 'English'
            case 'fr': return 'French'
            case 'es': return 'Spanish'
            case 'de': return 'German'
            default: return 'English'
        }
    }

    const getFlag = (lang) => {
        switch (lang) {
            case "English": return <GB className='flagIcon' />
            case "Spanish": return <ES className='flagIcon' />
            case "French": return <FR className='flagIcon' />
            case "German": return <DE className='flagIcon' />
            case "Portuguese": return <PT className='flagIcon' />
            case "Mandarin": return <CN className='flagIcon' />
            case "Hindi": return <IN className='flagIcon' />
            case "Arabic": return <SA className='flagIcon' />
            case "Japanese": return <JP className='flagIcon' />
            case "Russian": return <RU className='flagIcon' />
            case "Irish": return <IE className='flagIcon' />
            default: return <GB className='flagIcon' />
        }
    }

    return (
        <Suspense fallback={<div>Loading...</div>}>
        <div className="column ai-centre gap40">
            <div className="servicesScreenSec1 marginTop-50">
                <span className='primary title2 white'>Reply Page</span>
            </div>
            <div className='width80 row gap40 mobile-column-reverse mobileW100'>
            


            {messagesJSON && <div className='column ai-start width50 gap12 mobileW100'>
                
                <div className='row gap8 ai-centre'>
                    <KeyboardReturn />
                    <span className='subtitle2'>Send a new reply</span>
                </div>

                <div className='replyToolBubble'>
                
                    <div className='column ai-centre gap24 width100'>
                    <TextInput className='textInputService' variant="outlined" label="Recipient Email*" size='small' type="email" autoCorrect="off" autoCapitalize="none" disabled={true}
                        value={recipient} onChange={(e) => setRecipient(e.target.value)} />

                    <Autocomplete className='textInputService' disablePortal options={languageOptions} defaultValue={language} value={language} disabled={true}
                        renderInput={(params) => <TextInput {...params} variant="outlined" label="Language" value={language} size='small' />} onChange={(e) => setLanguage(e.target.textContent)}
                        renderOption={(props, option) => (
                            <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                                {getFlag(option)}
                                {option}
                            </Box>
                        )} />
                    </div>
                    <VoxMailTool propsData={toolProps} sending={setSending} addMessage={addMessage}/>
                 </div>
            </div>}

            {/* <span>----------------------------------------------------------------------------------------------------------------</span> */}

            <div className='column ai-start width50 gap32 noYOverFlow maxHeight700px mobileW100'>
                <div className='row gap8 ai-centre'>
                    <Forum />
                    <span className='subtitle2'>All messages</span>
                </div>

                <div className='column gap12 width100 height100 scrollY'>
                    {showMessages()}
                    {sending && <span className='alignCentre indigo subtitle2 marginTop20 width100'>Your message will appear here in a few moments ...</span>}
                </div>
                
            </div>

            {loadError !== "" && <span>{loadError}</span>}

            </div>

           
        </div>
        </Suspense>
    );
}