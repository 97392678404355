import styled from '@emotion/styled';
import Button from '@mui/material/Button';


export const PrimaryButton = styled(Button)({
    backgroundColor: '#FFFFFF',
    color: '#3E363F',
    borderColor: '#3E363F',
    borderRadius: '64px',
    fontWeight: '600',
    fontSize: '17px',
    fontFamily: 'RedHatText',
    textTransform: 'none',

    ':hover': {
        backgroundColor: '#F5F5F7',
        borderColor: '#3E363F',
    },
    ':disabled': {
        backgroundColor: '#E1E0E3',
        borderColor: '#A5A2AE',
        color: '#A5A2AE'
    },
    ':pressed': {
        backgroundColor: '#EDEDED',
        borderColor: '#3E363F',
    }
})

export const SecondaryButton = styled(Button)({
    backgroundColor: '#3E363F',
    color: '#FFFFFF',
    borderColor: '#3E363F',
    borderRadius: '64px',
    fontWeight: '600',
    fontSize: '17px',
    fontFamily: 'RedHatText',
    textTransform: 'none',

    ':hover': {
        backgroundColor: '#262126',
        borderColor: '#262126',
    },
    ':disabled': {
        backgroundColor: '#E1E0E3',
        borderColor: '#A5A2AE',
        color: '#6C6A71'
    },
    ':pressed': {
        backgroundColor: '#120F12',
        borderColor: '#120F12',
    }
})

export const TertiaryButton = styled(Button)({
    backgroundColor: '#B83106',
    color: '#FFFFFF',
    borderColor: '#B83106',
    borderRadius: '64px',
    fontWeight: '600',
    fontSize: '17px',
    fontFamily: 'RedHatText',
    textTransform: 'none',

    ':hover': {
        backgroundColor: '#B83106',
        borderColor: '#B83106',
    },
    ':disabled': {
        backgroundColor: '#E1E0E3',
        borderColor: '#A5A2AE',
        color: '#6C6A71'
    },
    ':pressed': {
        backgroundColor: '#992905',
        borderColor: '#992905',
    }
})

export const GreenButton = styled(Button)({
    backgroundColor: '#A7F2CE',
    variant: 'medium',
})

export const GreenButton2 = styled(Button)({
    backgroundColor: '#83C17B',
    variant: 'medium',
    ':hover': {
        backgroundColor: '#70A16A'
    }
})

export const RedButton = styled(Button)({
    backgroundColor: 'red',
    color: 'white',
    variant: 'medium',
    ':hover': {
        backgroundColor: '#F97C93'
    }
})

export const GreyButton = styled(Button)({
    backgroundColor: '#F2F3FD',
    color: 'white',
    variant: 'medium',
    ':hover': {
        backgroundColor: '#A39FDB'
    }
})

export const PurpleOutlineButton = styled(Button)({
    borderColor: '#766CE3',
    color: '#766CE3',
    ':hover': {
        backgroundColor: '#DFDDFE33'
    }
})