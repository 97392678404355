export const lineHeights = [
    3.25, 3.90, 4.15, 4.57, 4.30, 4.28, 4.21, 4.02, 4.47, 14.35,
    22.84, 24.38, 24.84, 24.03, 22.59, 25.09, 26.18, 25.90, 25.53, 28.66,
    28.26, 30.14, 30.54, 30.28, 30.56, 30.84, 30.43, 29.52, 28.26, 29.44,
    30.13, 29.07, 28.47, 28.63, 28.11, 27.29, 26.30, 25.18, 24.27, 23.19,
    22.14, 20.87, 19.63, 18.37, 17.08, 15.78, 14.44, 13.15, 11.89, 11.25,
    10.05, 13.26, 20.71, 22.65, 22.42, 21.38, 20.15, 23.54, 24.36, 27.99,
    27.02, 27.68, 27.53, 26.83, 27.39, 30.32, 30.16, 30.44, 29.38, 30.07,
    31.17, 31.34, 30.46, 29.90, 29.19, 28.50, 27.70, 27.15, 26.79, 28.12,
    28.29, 27.80, 27.02, 26.10, 25.04, 26.03, 25.02, 24.35, 24.05, 24.14,
    23.54, 22.88, 22.27, 22.34, 22.76, 23.68, 25.41, 24.77, 27.31, 28.63,
    27.67, 27.33, 26.37, 25.33, 24.08, 23.96, 23.34, 23.87, 23.56, 27.61,
    26.85, 27.25, 26.22, 25.47, 25.32, 24.88, 28.51, 29.98, 29.30, 28.43,
    27.19, 26.06, 24.92, 24.13, 23.80, 24.03, 23.34, 22.35, 21.09, 21.34,
    20.27, 20.15, 23.14, 22.91, 22.51, 22.84, 21.67, 21.24, 21.37, 21.03,
    20.80, 19.98, 20.14, 19.89, 23.76, 23.51, 22.75, 21.74, 20.56, 19.34,
    25.60, 27.30, 27.56, 26.49, 25.28, 24.04, 22.79, 21.53, 20.23, 18.91,
    17.54, 16.17, 14.80, 13.40, 12.03, 10.66, 9.30, 8.03, 6.74, 5.51,
    4.41, 3.36, 2.83, 12.10, 14.16, 16.55, 23.74, 25.78, 27.69, 28.12, 29.01,
    27.75, 27.04, 27.94, 27.97, 28.22, 28.90, 27.91, 28.23, 27.13, 26.29,
    25.42, 24.89, 26.44, 25.65, 24.84, 24.61, 24.75, 24.08, 23.16, 22.15,
    21.31, 22.43, 22.38, 24.15, 25.60, 25.45, 24.30, 23.41, 22.96, 22.03,
    21.70, 22.10, 21.14, 20.52, 20.42, 21.06, 27.05, 25.31, 23.25, 21.67,
    21.62, 21.38, 20.59, 19.81, 20.86, 20.35, 20.64, 20.56, 19.49, 19.39,
    20.18, 25.30, 28.13, 28.30, 27.30, 28.03, 27.71, 26.94, 25.82, 24.67,
    23.73, 22.88, 22.19, 22.53, 21.78, 21.32, 23.16, 25.04, 25.30, 27.85,
    27.07, 25.84, 25.38, 24.70, 24.54, 25.94, 25.87, 25.63, 24.55, 24.61,
    23.75, 22.87, 22.47, 23.40, 22.98, 21.86, 22.64, 22.77, 21.74, 21.40,
    21.53, 23.78, 22.50, 21.22, 20.30, 19.45, 19.83, 20.05, 22.35, 23.58,
    22.68, 22.57, 21.64, 20.72, 20.74, 20.67, 20.00, 18.88, 17.73, 21.36,
    22.00, 20.80, 21.90, 25.59, 27.88, 27.94, 27.18, 26.12, 26.02, 25.51,
    24.26, 23.01, 21.79, 20.57, 19.32, 18.08, 16.86, 15.64, 14.47, 13.26,
    12.08, 10.92, 9.72, 8.62, 7.52, 6.47, 5.45, 4.64, 4.02, 3.44, 2.95,
    2.47, 2.03, 1.70, 8.46, 20.55, 22.38, 22.14, 25.93, 29.45, 29.22, 30.27,
    29.90, 30.14, 29.98, 33.08, 33.51, 32.38, 31.20, 30.01, 28.86, 29.83,
    28.68, 27.45, 26.64, 28.43, 29.75, 28.90, 28.31, 27.43, 26.23, 25.01,
    23.84, 22.80, 21.79, 20.47, 19.15, 17.81, 16.46, 15.10, 13.78, 12.47,
    11.20, 10.05, 8.95, 7.89, 6.89, 6.03, 5.32, 4.72, 4.22, 3.70, 3.26,
    2.83, 2.46, 2.04, 1.64, 1.29, 1.02, 7.02, 9.38, 20.89, 22.01, 22.19,
    21.84, 21.22, 22.19, 28.59, 31.55, 32.34, 31.27, 29.93, 29.58, 31.08,
    30.63, 30.32, 29.83, 29.13, 27.99, 27.25, 26.82, 25.84, 25.98, 27.72,
    29.58, 29.71, 28.96, 27.75, 26.39, 25.49, 25.97, 25.95, 26.14, 27.18,
    26.57, 26.61, 26.94, 26.14, 25.57, 25.40, 25.02, 24.35, 23.13, 21.82,
    22.15, 22.40, 24.13, 26.13, 25.44, 24.43, 23.20, 22.97, 23.62, 23.68,
    23.57, 27.60, 26.50, 25.65, 24.60, 24.41, 23.52, 22.29, 21.98, 22.10,
    21.20, 20.80, 20.14, 19.72, 19.39, 18.31, 17.27, 16.24, 15.62, 14.65,
    13.35, 12.06, 10.81, 9.67, 8.59, 7.57, 6.65, 5.80, 5.03, 4.37, 3.76,
    3.24, 2.75, 2.28, 1.86, 1.47, 1.21, 1.03, 12.11, 21.07, 21.65, 20.18,
    18.80, 17.54, 23.95, 24.67, 23.81, 24.46, 25.37, 25.66, 25.40, 24.54,
    26.97, 28.53, 27.62, 28.14, 27.96, 27.76, 27.03, 26.28, 27.44, 26.55,
    27.54, 28.03, 28.37, 27.58, 26.64, 25.55, 27.13, 27.90, 27.34, 26.48,
    26.04, 28.02, 28.79, 28.16, 27.14, 26.36, 27.52, 28.62, 28.66, 27.47,
    26.63, 26.39, 27.36, 28.00, 27.68, 27.01, 26.38, 25.33, 24.11, 26.65,
    26.68, 25.60, 24.51, 24.06, 24.45, 23.82, 26.59, 27.90, 26.83, 26.21,
    25.29, 25.86, 25.21, 25.15, 24.28, 23.44, 22.39, 21.96, 22.19, 22.83,
    22.48, 21.34, 20.09, 18.82, 17.62, 19.28, 18.67, 20.67, 20.71, 20.19,
    19.49, 19.20, 21.55, 21.77, 21.60, 22.53, 22.34, 24.06, 23.33, 22.40,
    23.33, 23.34, 24.15, 23.39, 22.37, 21.16, 20.19, 19.42, 19.01, 19.20,
    18.07, 16.93, 20.40, 21.64, 20.64, 19.38, 18.15, 16.99, 15.88, 20.76,
    21.43, 21.40, 20.90, 19.75, 26.55, 25.82, 25.41, 25.20, 24.31, 24.83,
    25.50, 24.62, 24.71, 24.83, 24.17, 23.58, 23.00, 22.48, 23.32, 22.61,
    21.46, 20.36, 19.56, 18.69, 18.07, 16.78, 15.46, 19.97, 21.40, 20.89,
    19.65, 18.39, 17.16, 15.95, 14.75, 13.53, 12.37, 11.18, 10.03, 8.96,
    7.93, 6.94, 6.00, 5.11, 4.30, 3.52, 2.83, 2.32, 1.94, 1.84, 16.19,
    29.51, 28.86, 29.54, 28.83, 27.89, 26.95, 25.69, 24.38, 23.68, 23.04,
    22.29, 22.36, 21.86, 21.36, 21.18, 27.39, 28.98, 27.84, 26.88, 26.50,
    25.64, 25.31, 26.09, 26.82, 26.20, 26.43, 25.30, 24.09, 24.04, 25.18,
    25.12, 24.09, 23.55, 23.77, 23.19, 22.36, 23.47, 23.46, 23.99, 24.70,
    24.06, 23.17, 22.21, 22.76, 21.95, 21.34, 22.18, 22.32, 21.39, 25.08,
    25.45, 24.70, 25.34, 24.47, 24.15, 23.36, 22.54, 21.97, 25.51, 26.63,
    25.59, 24.79, 25.11, 24.67, 23.50, 22.37, 23.19, 23.70, 22.98, 23.52,
    22.44, 21.53, 20.69, 19.49, 18.29, 17.07, 15.88, 14.73, 13.64, 12.65,
    13.93, 16.14, 26.68, 27.84, 27.99, 29.23, 29.54, 28.49, 27.27, 26.01,
    25.24, 24.60, 23.46, 23.03, 25.76, 25.08, 23.82, 22.65, 21.58, 20.67,
    20.38, 19.45, 18.19, 22.29, 21.69, 20.84, 20.12, 19.74, 19.29, 19.14,
    18.15, 18.43, 19.65, 22.34, 21.74, 20.89, 19.93, 19.25, 18.75, 19.20,
    19.01, 17.91, 16.66, 15.47, 14.25, 13.02, 11.88, 10.75, 9.70, 8.74,
    7.92, 7.13, 6.39, 5.73, 5.16, 4.61, 4.12, 3.57, 3.07, 5.37, 12.61,
    15.79, 15.48, 17.87, 18.70, 18.52, 18.27, 19.02, 20.02, 25.03, 29.26,
    28.57, 27.59, 26.71, 26.60, 26.73, 26.03, 25.68, 25.70, 25.44, 24.80,
    24.40, 23.36, 24.45, 23.70, 22.60, 21.62, 23.22, 25.83, 25.54, 24.67,
    23.86, 22.88, 21.71, 21.70, 21.00, 20.88, 20.43, 22.40, 22.89, 21.96,
    20.74, 20.24, 19.12, 18.43, 17.51, 18.02, 18.15, 18.74, 18.85, 17.81,
    16.58, 17.69, 17.89, 19.36, 18.75, 23.40, 24.37, 23.28, 22.47, 21.35,
    20.33, 19.44, 18.47, 17.30, 16.23, 18.16, 22.08, 22.66, 21.63, 20.36,
    19.15, 17.85, 16.55, 15.24, 13.91, 12.63, 11.34, 10.15, 9.00, 7.81,
    6.63, 5.53, 4.43, 3.55, 2.64, 1.84, 1.32, 1.04, 0.67, 0.50, 0.50,
    3.36, 2.87, 2.40, 1.97, 11.30, 20.80, 25.41, 26.19, 25.18, 23.85, 25.28,
    25.75, 25.21, 24.40, 23.22, 25.87, 26.03, 25.21, 27.76, 28.93, 30.12,
    30.11, 29.78, 29.33, 28.70, 27.69, 26.82, 25.87, 25.22, 29.38, 30.33,
    29.04, 28.61, 27.54, 26.75, 26.36, 25.89, 25.57, 24.73, 25.45, 24.34,
    23.73, 23.53, 23.56, 23.25, 22.75, 22.20, 23.88, 24.39, 23.53, 22.49,
    21.38, 20.32, 19.12, 17.90, 16.60, 15.34, 14.12, 12.98, 11.84, 10.74,
    9.67, 8.69, 7.73, 10.49, 15.29, 16.93, 18.95, 18.12, 17.01, 17.88, 21.00,
    21.86, 25.04, 24.59, 23.36, 22.06, 25.11, 25.84, 24.67, 24.00, 23.19,
    22.36, 21.56, 21.20, 20.23, 19.30, 18.20, 17.00, 15.73, 14.54, 13.35,
    12.22, 11.07, 9.93, 8.84, 7.80, 1.20, 1.68, 2.26
  ];
  
  export const articles = [
    {
        id: "create-your-own-characters",
        title: "Creating your own D&D character makes it a better game",
        date: 1722435244000,
        content: `Picture, if you will, a universe where you're not you, but also totally you, just with pointy ears and a penchant for setting things on fire with your mind. Welcome to the wonderful world of creating your own D&D character! Now, you might think, "Why bother? Surely there's a perfectly good pre-made elf wizard collecting dust in the rulebook." And you'd be right, in the same way that there's a 
            perfectly good cardboard cutout of a person you could take to a party instead of going yourself. But where's the fun in that? You see, crafting your very own character is like building a custom-made vehicle for your imagination to joyride through the cosmos of make-believe. It's you, but with all the bells and whistles you've always secretly wanted. Always fancied being a smooth-talking rogue with a 
            heart of gold and a pocket full of lockpicks? Now's your chance! Or perhaps you'd rather be a muscle-bound barbarian whose solution to every problem is to hit it really, really hard. The multiverse is your oyster, and you're the pearl!
            But here's the kicker: when you pour a bit of yourself into your character, something magical happens. Suddenly, you're not just rolling dice and moving a miniature around a board. You're living, breathing, and possibly burping in character. You know exactly how your half-orc paladin would react to finding a kitten in distress because, well, it's basically you in a bad mood with green skin and a holy mission.
            This personal touch makes role-playing as easy as falling off a log (which, coincidentally, is something your clumsy ranger might do). No more awkward pauses while you wonder, "What would Thrognar the Unspeakable do?" Because Thrognar is you on a very bad hair day with an axe fixation.
            And let's not forget the sheer, unadulterated joy of immersion. When you've lovingly crafted every detail of your character, from their tragic backstory to their irrational fear of cheese, you're not just playing a game. You're living an alternate life where the fate of the world hangs on your ability to persuade a dragon that your party's bard would make a terrible snack.
            In the end, creating your own D&D character is like being the architect, contractor, and interior decorator of your very own persona. It's a chance to explore the road not taken, to be the hero (or antihero) of your own story, and to discover just how much havoc you can wreak with a well-placed cantrip.
            So go forth, brave adventurer! Create that character who's an amalgamation of your wildest dreams and most embarrassing quirks. Because in the vast, improbable universe of D&D, the most fantastical creature of all is the one you've just made up.`
    },
    {
        id: "inventing-monsters",
        title: "Inventing monsters is a hallmark of a truly great Game-Master",
        date: 1712425144000,
        content: `Ah, the noble art of monster-making! Let's dive into this primordial soup of creativity, shall we?
            Picture yourself, a Dungeon Master, armed with nothing but a pencil, some graph paper, and an imagination fueled by too much caffeine and not enough sleep. You could, of course, flip open the Monster Manual and pluck out a perfectly serviceable goblin or gelatinous cube. But where's the fun in that? It's like serving microwave dinners at a gourmet restaurant - sure, it's food, but it lacks that certain je ne sais quoi.
            Now, imagine instead that you're Dr. Frankenstein, but instead of reanimating corpses, you're birthing fantastical beasties from the depths of your fevered mind. "It's alive!" you cry, as you scribble down the final details of your latest abomination - a creature that's part octopus, part chandelier, and part tax attorney. Your players won't know what hit them (literally, in the case of the chandelier parts).
            Creating your own monsters is like being a cosmic chef, whipping up a smorgasbord of the weird and wonderful. And the best part? Your players can't metagame their way out of it. No more of that "Oh, it's a troll? I'll just use fire" nonsense. When faced with your octopus-chandelier-lawyer monstrosity, they'll be too busy trying to figure out which end to stab and which end might sue them for damages.
            But it's not just about confounding your players (though that is a delightful perk). Crafting bespoke beasties allows you to tailor-make the perfect narrative nemesis. Need a villain that embodies your campaign's themes of corruption and decay? Why not a sentient fungus that feeds on broken promises and wears a very dapper hat? It's precisely this kind of monster that will stick in your players' minds long after the last die has rolled.
            Moreover, your homemade horrors can be perfectly calibrated to your party's strengths and weaknesses. Got a group that relies too heavily on the wizard's fireballs? Enter the Flame-Loving Flumph, a creature that gets stronger the more it's burnt. That'll teach them to solve all their problems by setting things on fire.
            And let's not forget the sheer, unbridled joy of unveiling your creation. There's nothing quite like the mix of confusion, fear, and "What in the name of Gygax is THAT?" on your players' faces when you describe your latest monster. It's like being a proud parent, if your child happened to have tentacles and a penchant for devouring souls.
            In the end, creating new monsters for D&D is like being the mad scientist of your own private Jurassic Park, but with less running and screaming (hopefully). It's your chance to populate your world with creatures that don't just challenge your players, but also capture their imaginations and possibly their characters (quite literally, in the case of that mimic you disguised as a comfortable armchair).
            So go forth, oh Master of Dungeons! Unleash your inner cryptozoologist and fill your world with beasts so bizarre they'd make H.P. Lovecraft reach for the smelling salts. Because in the grand, improbable tapestry of D&D, the most memorable threads are the ones you weave yourself - preferably with extra tentacles.`
    }
];