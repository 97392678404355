
import '../styling/constants/typography.css'
import { articles } from '../constants.js';
import React, { useEffect, useState } from 'react';
import Moment from 'moment';

export default function Article({id}) {

    const [selectedArticle, setSelectedArticle] = useState(null);

    useEffect(() => {

        articles.forEach(article => {
            if(article.id === id)
            {
                setSelectedArticle(article)
            }
        });
    }, [id]);

    const formatDate = (time) => {
        let date = Moment(time).format('MMM DD, YYYY')
        return date
    }

    return (
        <div className="padding20 width100 column ai-centre borderBox">

            {selectedArticle && <div className='column width100 gap32 padding20 borderBox'>

                <div className='column width100 ai-start'>
                    <span className='title3'>{selectedArticle.title}</span>
                    <span className=''>{formatDate(selectedArticle.date)}</span>
                </div>

                <div className='blurred-box lavender  padding24 gap20 column br-6 bg-ultraviolet20'>
                    <span className='alignLeft body1'>
                        {selectedArticle.content}
                    </span>
                </div>
            </div>}
        </div>
    );
}