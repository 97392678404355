import { Autocomplete, Checkbox, Radio, Switch, TextField } from "@mui/material";
import { styled } from "@mui/system";

export const TextInput = styled(TextField)({

  backgroundColor: "white",
  borderRadius: '12px',
  

  '& .MuiFilledInput-underline:before': {
    borderBottom: '1px solid #A5A2AE',
  },
  '& .MuiFilledInput-underline:hover:before': {
    borderBottom: '2px solid #3E363F', // Solid underline on hover
  },
  '& .MuiFilledInput-underline:after': {
    borderBottom: '2px solid #0193A2', // Solid underline on focus
  },
  '& .MuiFilledInput-underline.Mui-disabled:before': {
    borderBottom: '2px dotted #A5A2AE',
  },
  "& .MuiInputLabel-root.Mui-focused": {
    color: "#340482",
  },
  "& .MuiFilledInput-root": {
    backgroundColor: "#F4F4F8",
  },
  "& .Mui-disabled": {
    backgroundColor: "#F4F4F8",

  },
  "& .Mui-disabled.MuiFilledInput-adornedStart": {
    backgroundColor: "red",
  },





  '& .MuiOutlinedInput-border:before': {
    border: '1px solid #A5A2AE',
  },
  '& .MuiOutlinedInput-border:hover:before': {
    border: '2px solid #3E363F', // Solid underline on hover
  },
  '& .MuiOutlinedInput-border:after': {
    border: '2px solid #340482', // Solid underline on focus
  },
  '& .MuiOutlinedInput-border.Mui-disabled:before': {
    border: '2px dotted #A5A2AE',
  },
  "& .MuiOutlinedInput-root.Mui-focused": {
    color: "#3E363F",
  },
  "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: '#340482' //Border when focused
  },
  "& .MuiOutlinedInput-root": {
    backgroundColor: "white",
  },
  
  "& .Mui-disabled.MuiOutlinedInput-adornedStart": {
    backgroundColor: "red",
  }



})



export const AutoCompleteInput = styled(Autocomplete)({

  backgroundColor: "#F4F4F8",
  borderRadius: '8px',

  '& .MuiFilledInput-underline:before': {
    borderBottom: '1px solid #A5A2AE',

  },
  '& .MuiFilledInput-underline:hover:before': {
    borderBottom: '2px solid #3E363F', // Solid underline on hover
  },
  '& .MuiFilledInput-underline:after': {
    borderBottom: '2px solid #0193A2', // Solid underline on focus
  },

  '& .MuiFilledInput-underline.Mui-disabled:before': {
    borderBottom: '2px dotted #A5A2AE',

  },

  "& .MuiInputLabel-root.Mui-focused": {
    color: "#0193A1",
  }
})

export const TealCheckBox = styled(Checkbox)({

  color: '#0193A1',
  height: '15px',
  width: '15px',

  "&.Mui-checked": {
    color: "#0193A1",
  }
})


export const IndigoCheckBox = styled(Checkbox)({

  color: '#340482',
  height: '15px',
  width: '15px',

  "&.Mui-checked": {
    color: "#340482",
  },
  "&.Mui-disabled": {
    color: "#828285",
  }

})

export const TealRadio = styled(Radio)({

  color: '#000',
  height: '20px',
  width: '20px',

  "&.Mui-checked": {
    color: "#0193A1",
  }
})


export const IndigoRadio = styled(Radio)({

  color: '#000',
  height: '20px',
  width: '20px',

  "&.Mui-checked": {
    color: "#340482",
  },

  "&.Mui-disabled": {
    color: "#828285",
  }
})


export const PurpleSwitch = styled(Switch)({
  '& .MuiSwitch-switchBase.Mui-checked': {
    color: '#766CE3',
    '&:hover': {
      backgroundColor: '#F2F3FD',
    },
  },
  '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
    backgroundColor: '#766CE3',
  },
});